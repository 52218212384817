import authReducer from "./reducer/authReducer.js";
import  doctorReducer  from "./reducer/doctorReducer.js";
import { configureStore } from '@reduxjs/toolkit'

    import headerSlice from "../../features/common/headerSlice.js";
import rightDrawerSlice from "../../features/common/rightDrawerSlice.js";
import modalSlice from "../../features/common/modalSlice.js";
import  leadsSlice  from "../../features/leads/leadSlice.js";
import adminReducer from "./reducer/adminReducer.js";
import  orderReducer  from "./reducer/orderReducer.js";
import transactionReducer from "./reducer/transactionReducer.js";

const rootReducer = {
    auth: authReducer,
    doctor: doctorReducer,
    admin: adminReducer,
    order: orderReducer,
    transaction : transactionReducer,
  header : headerSlice,
  rightDrawer : rightDrawerSlice,
  modal : modalSlice,
  lead : leadsSlice,
};
export default rootReducer;