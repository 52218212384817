import React, { useCallback, useEffect } from "react";
import "../../App.css";
import img1 from "../assets/WhatsApp Image 2024-09-24 at 5.35.52 AM.jpeg";
import map from "../assets/WhatsApp Image 2024-09-24 at 6.05.15 AM.jpeg";
import { FaBrain } from "react-icons/fa6";
import { IoStar } from "react-icons/io5";
import { MdPeopleAlt } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { getSingleDoctor } from "../store/reducer/doctorReducer";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
const Doctordetail = () => {
  const { doctorInfo } = useSelector((state) => state.doctor)
  const { slug } = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const getData = useCallback(() => {
    dispatch(getSingleDoctor(slug))

  }, [dispatch])

  useEffect(() => {
    getData()
  }, [getData]);




  const submitAppointment = () => {
    // Get the current system time
    const currentTime = new Date();

    // Parse startTime and endTime into Date objects
    const parseTime = (timeStr) => {
      const [time, modifier] = timeStr.split(" ");
      let [hours, minutes] = time.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      } else if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      return date;
    };

    const startTime = parseTime(doctorInfo?.startTime); // e.g., "08:10 AM"
    const endTime = parseTime(doctorInfo?.endTime); // e.g., "10:20 PM"

    // Check if the doctor is active and the current time is within the valid time range
    if (doctorInfo?.doctorStatus === "Active" && currentTime >= startTime && currentTime <= endTime) {
      // Store the appointment in localStorage

      navigate(`/shadule/${slug}`);
      localStorage.setItem("appointment", doctorInfo?._id);
    } else if (doctorInfo?.doctorStatus !== "Active") {
      toast.error("Sorry we are not available at this time");
    } else {
      toast.error("The appointment is outside the available time range");
    }
  };




  return (
    <div className="h-[100vh] overflow-hidden flex flex-col justify-between">
      <div className="h-full doctor-detail relative">
        <Link className="absolute top-4 left-4 z-40" to={`/doctorlist?city=${doctorInfo?.city}`}>
          <IoArrowBack className="text-white text-xl" />
        </Link>
        <img
          src={img1}
          className="absolute h-full w-full object-cover opacity-70"
          alt=""
        />
      </div>
      <div className="w-full mx-auto bg-card text-card-foreground p-5 rounded-lg ">
        <h2 className="text-lg font-bold">{doctorInfo?.name}</h2>
        <p className="text-sm font-[500] text-[#1e78c0] mb-2">{doctorInfo?.profile}</p>
        <div className="flex justify-around space-x-4">
          <div className="flex items-center bg-gray-100 p-2 rounded-sm">
            <FaBrain className="text-lg text-[#ff11ae]" />

            <span className="ml-2 text-[11px]">
              Experiences
              <br />{" "}
              <span className="text-[#1e78c0] font-semibold"> {doctorInfo?.experience} Years</span>
            </span>
          </div>
          <div className="flex items-center bg-gray-100 px-4 py-2rounded-sm">
            <IoStar className="text-lg text-[#ffa42d]" />
            <span className="ml-2 text-[11px]">
              Rating
              <br />{" "}
              <span className="text-[#1e78c0] font-semibold"> {doctorInfo?.rate}/5.0</span>
            </span>
          </div>
          <div className="flex items-center bg-gray-100 p-2 rounded-sm">
            <MdPeopleAlt className="text-lg text-[#5111ff]" />
            <span className="ml-2 text-[11px]">
              Patients
              <br /> <span className="text-[#1e78c0] font-semibold"> {doctorInfo?.patients}+ </span>
            </span>
          </div>
        </div>
        <h3 className="text-[16px] font-bold mt-2">About</h3>
        <p className="text-[12px] text-justify text-gray-500">
          {doctorInfo?.about}
        </p>
        <h3 className="text-[16px] font-bold mt-2">Appointment Time</h3>
        <p className="text-[12px] text-justify text-gray-500">
          {doctorInfo?.startTime} of {doctorInfo?.endTime}
        </p>
        <div className="flex flex-col gap-2">
          <h3 className="text-base font-bold mt-3">Location</h3>
          <p className="text-[12px] text-justify text-gray-500">
            {doctorInfo?.city}
          </p>
          <div className="h-[20vh] rounded-lg overflow-hidden">

            {/* {doctorInfo?.map && (
<iframe src={doctorInfo?.map} width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
)} */}

          </div>
          <button

            className="bg-[#db2b6a] text-white p-3 rounded-lg  text-center w-[100%]"
            onClick={submitAppointment}
          >
            Make Appointment
          </button>
        </div>
      </div>
    </div>
  );
};

export default Doctordetail;
