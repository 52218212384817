import React from 'react'
import { IoArrowBack } from 'react-icons/io5'
import { MdPrivacyTip } from 'react-icons/md'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <>
            <div className="max-w-md mx-auto min-h-[100vh] flex flex-col justify-between shadow-lg rounded-lg overflow-hidden">
                <div className="p-4">
                    <div className="flex items-center justify-between mb-4">
                        <Link to="/details">
                            <IoArrowBack className="text-gray-600 text-xl" />
                        </Link>
                        <h1 className="text-lg font-semibold text-center text-gray-700 flex-grow">
                            About Us
                        </h1>
                    </div>


                    <div className='flex flex-col'>
                       
                        <Link className="flex items-center bg-white rounded-lg mb-3 p-3" to={"/privacy-policy"}>
                            <div className="ml-3">
                                <p className="font-medium">Privacy policy</p>
                            </div>
                        </Link>
                        <Link className="flex items-center  bg-white mb-3 rounded-lg p-3" to={"/terms-and-condition"}>
                            <div className="ml-3">
                                <p className="font-medium">Terms and condition</p>
                            </div>
                        </Link>
                        <Link className="flex items-center bg-white mb-3 rounded-lg p-3" to={"/about-us"}>
                            <div className="ml-3">
                                <p className="font-medium">About us</p>
                            </div>
                        </Link>
                        <Link className="flex items-center bg-white mb-3 rounded-lg p-3" to={"/cancellation"}>
                            <div className="ml-3">
                                <p className="font-medium">Cancellation</p>
                            </div>
                        </Link>
                        <Link className="flex items-center bg-white mb-3 rounded-lg p-3" to={"/pricing-details"}>
                            <div className="ml-3">
                                <p className="font-medium">Pricing Details</p>
                            </div>
                        </Link>
                        <Link className="flex items-center bg-white mb-3 rounded-lg p-3" to={"/contact-us"}>
                            <div className="ml-3">
                                <p className="font-medium">Contact Us</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About
