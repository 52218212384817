import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { api } from "../api";

// Define the async thunk for adding a category (or bank, based on the endpoint name)
export const addBank = createAsyncThunk(
    'transaction/addBank',
    async ({formData, doctorId}, { rejectWithValue }) => {
      try {
        // Example of how you might call the add bank API
const response = await api.post(`/addbank/${doctorId}`, formData, { withCredentials: true });

        return response.data;
      } catch (error) {
        // Log detailed error information
        console.error("API Error:", error.response ? error.response.data : error.message);
        return rejectWithValue(error.response?.data || { message: "Server Error" });
      }
    }
  );


  export const updatebank = createAsyncThunk(
    "transaction/updatebank",
    async ({ doctorId, formData }, { rejectWithValue, fulfillWithValue }) => {
      try {
        const { data } = await api.put(
          `/updatebank/${doctorId}`,
          formData,
          { withCredentials: true }
        );
  
        return fulfillWithValue(data);
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  export const getBankbyId = createAsyncThunk(
    "transaction/get-bank",
    async (id, { rejectWithValue, fulfillWithValue }) => {
      try {
        const { data } = await api.get(
          `/get-bank/${id}`,
       
          { withCredentials: true }
        );
  
        return fulfillWithValue(data);
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const getbanks = createAsyncThunk(
    "/admin/getbanks",
    async (_, { rejectWithValue, fulfillWithValue }) => {
      try {
        const { data } = await api.get(`/admin/getbanks`, {
          withCredentials: true,
        });
  
        return fulfillWithValue(data);
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const WithdrawbyId = createAsyncThunk(
    "transaction/withdrawal",
    async ({ id, amount }, { rejectWithValue, fulfillWithValue }) => {
      try {
        const { data } = await api.post(`/withdrawal/${id}`, { amount }, { withCredentials: true });
        return fulfillWithValue(data);
      } catch (error) {
        return rejectWithValue(error.response?.data || { message: "Server Error" });
      }
    }
  );
  
  export const showWithdrawls = createAsyncThunk(
    "transaction/get-withdrawal",
    async ({id,transactionId, page, limit}, { rejectWithValue, fulfillWithValue }) => {
      try {
        const { data } = await api.get(`/get-withdrawal/${id}?transactionId=${transactionId}&page=${page}&limit=${limit}`, {
          withCredentials: true,
        });
  
        return fulfillWithValue(data);
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  export const PendingWithdraw = createAsyncThunk(
    "/admin/pending-withdrawal",
    async ({transactionId, page, limit}, { rejectWithValue, fulfillWithValue }) => {
      try {
        const { data } = await api.get(`/admin/pending-withdrawal?transactionId=${transactionId}&page=${page}&limit=${limit}`, {
          withCredentials: true,
        });
  
        return fulfillWithValue(data);
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  export const allWithdraws = createAsyncThunk(
    "/admin/all-withdrawal",
    async ({transactionId, page, limit}, { rejectWithValue, fulfillWithValue }) => {
      try {
        const { data } = await api.get(`/admin/all-withdrawal?transactionId=${transactionId}&page=${page}&limit=${limit}`, {
          withCredentials: true,
        });
  
        return fulfillWithValue(data);
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  export const updateWithdraws = createAsyncThunk(
    "/admin/update-withdrawal",
    async ({ id, status }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post(`/admin/update-withdrawal/${id}`, { status }, {
                withCredentials: true,
            });

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
  
  

export const transactionReducer = createSlice({
  name: "transaction",
  initialState: {
    loader: false,
    errorMessage: "",
    successMessage: "",
    transactionDetail: null, // This can be used to store transaction details if needed
  },
  reducers: {
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
    transaction_reset: (state) => {
      state.transactionDetail = null; // Resetting transactionDetail
      state.errorMessage = ""; // Optionally reset error message as well
      state.successMessage = ""; // Optionally reset success message as well
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBank.pending, (state) => {
        state.loader = true;
        state.errorMessage = "";
      })
      .addCase(addBank.fulfilled, (state, action) => {
        state.loader = false; // Reset loader
        state.successMessage = action.payload.message || "Category added successfully!"; 
        state.transactionDetail = action.payload;
      })
      .addCase(addBank.rejected, (state, payload) => {
        let addingbank = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.addingbank = addingbank;
      })
      //
      .addCase(updatebank.pending, (state) => {
        state.loader = true;
      })
      .addCase(updatebank.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(updatebank.fulfilled, (state, { payload }) => {
        let updatedata = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.updatedata = updatedata;
      })
      .addCase(getbanks.pending, (state) => {
        state.loader = true;
      })
      .addCase(getbanks.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getbanks.fulfilled, (state, { payload }) => {
        let bankData = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.bankData = bankData;
      })
      .addCase(WithdrawbyId.pending, (state) => {
        state.loader = true;
      })
      .addCase(WithdrawbyId.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(WithdrawbyId.fulfilled, (state, { payload }) => {
        let withdrawdatabyid = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.withdrawdatabyid = withdrawdatabyid;
      })
      .addCase(showWithdrawls.pending, (state) => {
        state.loader = true;
      })
      .addCase(showWithdrawls.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(showWithdrawls.fulfilled, (state, { payload }) => {
        let showwithdrawsbyid = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.showwithdrawsbyid = showwithdrawsbyid;
      })
      .addCase(PendingWithdraw.pending, (state) => {
        state.loader = true;
      })
      .addCase(PendingWithdraw.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(PendingWithdraw.fulfilled, (state, { payload }) => {
        let pendingwithdrawbank = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.pendingwithdrawbank = pendingwithdrawbank;
      })
      .addCase(allWithdraws.pending, (state) => {
        state.loader = true;
      })
      .addCase(allWithdraws.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(allWithdraws.fulfilled, (state, { payload }) => {
        let showallwithdraw = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.showallwithdraw = showallwithdraw;
      })

      .addCase(getBankbyId.pending, (state) => {
        state.loader = true;
      })
      .addCase(getBankbyId.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getBankbyId.fulfilled, (state, { payload }) => {
    
        let docbankdetail = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.docbankdetail = docbankdetail;
      })

      .addCase(updateWithdraws.pending, (state) => {
        state.loader = true;
    })
    .addCase(updateWithdraws.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
    })
    
    .addCase(updateWithdraws.fulfilled, (state, { payload }) => {
        const updatedWithdraw = payload.data;
        state.successMessage = payload.message;
        state.loader = false;

        state.pendingwithdrawbank = state.pendingwithdrawbank.map((withdraw) =>
            withdraw.transactionId === updatedWithdraw.transactionId ? updatedWithdraw : withdraw
        )
        
      
    });
  },
});

// Export actions and reducer
export const { messageClear, transaction_reset } = transactionReducer.actions;
export default transactionReducer.reducer;
