import { AiFillHome } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { BsCalendar3 } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { Link, useLocation } from "react-router-dom";

export default function MobileNavBar() {
  const location = useLocation();

  return (
    <nav className="fixed  bottom-0 left-0 right-0 bg-white max-w-[450px] mx-auto">
      <div className="flex justify-around items-center py-2">
        <Link to='/'>
          <NavItem 
            icon={<AiFillHome />} 
            label="Home" 
            isActive={location.pathname === '/'} 
          />
        </Link>
        <Link to='/location'>
          <NavItem 
            icon={<FiSearch />} 
            label="Search" 
            isActive={location.pathname === '/location'} 
          />
        </Link>
        <Link to='/orders'>
          <NavItem 
            icon={<BsCalendar3 />} 
            label="Schedule" 
            isActive={location.pathname === '/orders'} 
          />
        </Link>
        <Link to='/details'>
          <NavItem 
            icon={<CgProfile />} 
            label="Profile" 
            isActive={location.pathname === '/details'} 
          />
        </Link>
      </div>
    </nav>
  );
}

function NavItem({ icon, label, isActive }) {
  return (
    <div className="flex flex-col items-center">
      <div className={`text-2xl ${isActive ? 'text-blue-500' : 'text-gray-400'}`}>
        {icon}
      </div>
      <span className={`text-xs mt-1 ${isActive ? 'text-blue-500' : 'text-gray-400'}`}>
        {label}
      </span>
    </div>
  );
}
