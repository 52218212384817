import React from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { Link } from "react-router-dom";

const Cancellation = () => {
  return (
    <div className=" p-5 min-h-[100vh]">
      <div className="text-3xl rounded-lg border h-8 w-8">
        <Link to='/about'> <MdOutlineKeyboardArrowLeft /></Link>
      </div>
      <div className="mb-10   pt-10">
        <div className="main">
          <h2 className="text-xl font-bold pb-5">
            Cancellation and Refund Policy
          </h2>
        </div>
        <div className="">
          <span className="text-sm">
            We understand that plans can change, so we’ve outlined our
            cancellation and refund policy below for clarity.
          </span>
        </div>
        <div className="mt-5">
          <ol>
            <li className="font-bold pb-4">1. Full Refund of Doctor’s Fee</li>
            <span className="pt-4 pb-4 text-sm">
              If you need to cancel an appointment, rest assured that the
              doctor’s fee will be fully credited to you <b>within 3 to 7 working days</b>. This ensures that you
              are not charged for services you do not receive due to
              cancellation.
            </span>
            <li className="font-bold pt-4 pb-4">
              2. Full Refund of Doctor’s Fee
            </li>
            <span className="text-sm">
              Please note that the platform service charge is non-refundable.
              This fee is retained to cover operational and service-related
              costs associated with providing our platform services. As such, if
              an appointment is canceled, this charge will not be reimbursed.
            </span>{" "}
            <br /> <br />
            <span className="pb-4 text-sm">
              We appreciate your understanding and encourage you to reach out if
              you have any questions regarding our policy.
            </span>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Cancellation;
