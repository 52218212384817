import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const DoctorPrivateRoute = ({ children }) => {
  const location = useLocation();
  const { token: reduxToken } = useSelector((store) => store.doctor);
  const [token, setToken] = useState(reduxToken || localStorage.getItem("token"));

  // Set token on initial render if it's not in Redux but exists in localStorage
  useEffect(() => {
    if (!reduxToken && localStorage.getItem("token")) {
      setToken(localStorage.getItem("token"));
    }
  }, [reduxToken]);

  // If token is present, allow access to the route
  if (token) {
    return children;
  }

  // Redirect to login page if not authenticated
  return <Navigate to="/doclogin" state={{ from: location }} replace />;
};

export default DoctorPrivateRoute;

