import React, { useCallback, useEffect, useState } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserDetails, login } from "../store/reducer/authReducer";
import { toast } from "react-toastify";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append("phone", phone)
    formData.append("password", password)
    await dispatch(login(formData)).then((res) => {
      if (res?.payload?.success) {
        toast.success(res.payload.message);
        navigate("/")
      } else {
        toast.error(res.payload.message);
      }
    })
  }



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const getData = useCallback(() => {
    dispatch(getUserDetails());
    }, [ dispatch]);

useEffect(() => {
    getData()   
}, [getData]);
  return (
    <div className="h-[100vh] bg-[#f7f8ff] p-5 relative">
      <div>
        <div className="text-3xl rounded-lg border h-8 w-8">
          <Link to="/">
            {" "}
            <MdOutlineKeyboardArrowLeft />
          </Link>
        </div>
      </div>
      <div className="flex items-center flex-col h-[80%] gap-5 pt-10">
        <h2 className="text-3xl font-semibold">
          Welcome Back! Glad to See You Again.
        </h2>

        <form className="flex flex-col items-center w-full mt-10">
          <input
            type="phone"
            placeholder="Enter your phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="mb-4 p-4 border-[0.5px] border-border bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
          />


          <div className="relative w-full mb-4">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}

              className="p-4 border-[0.5px] border-border bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-muted-foreground text-xl text-gray-400"
            >
              {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
          <Link
            to="/forget"
            className="text-accent  mb-4 w-full flex justify-end"
          >
            <p className="text-gray-500 hover:text-[#4486ff]">
              Forgot Password?
            </p>
          </Link>
          <Link className="bg-[#4486ff] text-white p-4 rounded-md w-full text-center mt-2" onClick={handleSubmit}>
            Login
          </Link>
        </form>
        <p className="text-sm text-center font-[400]  w-full">
        Don't have an account?{" "}
        <span className="text-[#37C2C1] font-[400]">
          <Link to="/register">Register Here</Link>
        </span>
      </p>
      </div>
    
    </div>
  );
};

export default Login;
