import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";

// Main
import LoginRegister from "./main/Authentication/LoginRegister";
import MainLogin from "./main/Authentication/Login";
import MainRegister from "./main/Authentication/Register";
import ForgetPass from "./main/Authentication/ForgetPass";
import { Otp } from "./main/Authentication/Otp";
import { NewPassword } from "./main/Authentication/NewPassword";
import Passchanged from "./main/Authentication/Passchanged";
import LanguageSelector from "./main/Appointment/LanguageSelect";
import Home from "./main/Appointment/Home";
import Doctorlist from "./main/Appointment/Doctorlist";
import Doctordetail from "./main/Appointment/Doctordetail";
import AppointmentShadule from "./main/Appointment/AppointmentShadule";
import Mainhome from "./main/Home/Mainhome";
import Categories from "./main/Home/Categories";
import Digestion from "./main/Home/Digestion";
import Details from "./main/Home/Details";
import ChatInterface from "./main/Home/Chat";
import PatientData from "./main/Appointment/PatientData";
import OrderDetail from "./main/Appointment/OrderDetail";
import CheckOut from "./main/Appointment/CheckOut";
import PrivateRoute from "./main/Layout/Private";
import AdminPrivate from "./main/Layout/AdminPrivate";
import City from "./main/Home/City";
import Orders from "./main/Appointment/Orders";
import DoctorPrivateRoute from "./DoctorPrivateRoute";
import { useDispatch } from "react-redux";
import { loginDoctor } from "./main/store/reducer/doctorReducer";
import PrivacyPolicy from "./main/Home/PrivacyPolicy";
import About from "./main/Home/About";
import AboutUs from "./main/Home/AboutUs";
import TermsAndCondition from "./main/Home/TermsAndCondition";
import Invoice from "./doctordashboard/features/leads/Invioce";
import ContactUs from "./main/Home/ContactUs";
import PricingDetails from "./main/Home/PricingDetails";
import Cancellation from "./main/Home/Cancellation";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));
// Importing pages
const DocLayout = lazy(() => import("./doctordashboard/containers/Layout"));
const DocLogin = lazy(() => import("./doctordashboard/pages/Login"));
const DocForgotPassword = lazy(() => import("./doctordashboard/pages/ForgotPassword"));
const DocRegister = lazy(() => import("./doctordashboard/pages/Register"));
const DocDocumentation = lazy(() => import("./doctordashboard/pages/Documentation"));

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
// const token = checkAuth();

function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    if (token && user) {
      // Dispatch an action to update Redux state with the token and user
      dispatch(loginDoctor({ token, user }));
    }
  }, [dispatch]);

  return (
    <>
      <Router>

      <div className="bg-gray-300">
          <div className="max-w-[450px] mx-auto bg-[#f7f8ff] text-black">
            <Routes>
              <Route path="/registers" element={<LoginRegister />} />

              <Route path="/login" element={<MainLogin />} />
              <Route path="/register" element={<MainRegister />} />
              <Route path="/forget" element={<ForgetPass />} />
              <Route path="/otp" element={<Otp />} />
              <Route path="/newpassword" element={<NewPassword />} />
              <Route path="/passchanged" element={<Passchanged />} />

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/about" element={<About />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/terms-and-condition" element={<TermsAndCondition />} />
              <Route path="/cancellation" element={<Cancellation />} />
              <Route path="/pricing-details" element={<PricingDetails/>} />
              <Route path="/contact-us" element={<ContactUs/>} />
                           
              <Route path="/" element={<PrivateRoute/>}>    
                 
              {/* Appointment */}
              <Route path="language" element={<LanguageSelector />} />
              <Route path="" element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="location" element={<City />} />
              <Route path="doctorlist" element={<Doctorlist />} />
              <Route path="doctordetail/:slug" element={<Doctordetail />} />
              <Route path="shadule/:slug" element={<AppointmentShadule />} />
              <Route path="patientdata" element={<PatientData />} />
              <Route path="orderdetail/:id" element={<OrderDetail />} />
              <Route path="checkout" element={<CheckOut />} />
              {/* Home */}
              <Route path="mainhome" element={<Mainhome />} />
         
              <Route path="categories" element={<Categories />} />
              <Route path="digestion" element={<Digestion />} />
              <Route path="details" element={<Details />} />
              <Route path="chat" element={<ChatInterface />} />
              <Route path="orders" element={<Orders />} />
              <Route path="user/invoice" element={<Invoice/> }/>
              </Route>
            </Routes>
          </div>
        </div>

        <Routes>
          <Route path="/adminlogin" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/adminregister" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/app/*" element={
           <AdminPrivate>
            <Layout />
           </AdminPrivate>
            } 
            
            />
          {/* <Route path="*" element={<Navigate to={token ? "/app/welcome" : "/login"} replace />}/> */}


          {/* Doctor dashboard */}
          <Route path="/doclogin" element={<DocLogin />} />
          <Route path="/docforgot-password" element={<DocForgotPassword />} />
          <Route path="/docregister" element={<DocRegister />} />
          <Route path="/docdocumentation" element={<DocDocumentation />} />
        
          
          {/* Place new routes over this */}
          {/* <Route path="/apps/*" element={<DocLayout />} /> */}
          <Route
          path="/apps/*"
          element={
            <DoctorPrivateRoute>
              <DocLayout />
            </DoctorPrivateRoute>
          }
        />

        
        </Routes>
       
      </Router>
    </>
  );
}

export default App;
