import React from 'react'
import {Link} from "react-router-dom"
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
const PrivacyPolicy = () => {
  const handleGo=()=>{
    window.history.back()
  }
  return (
    <>
      <div className='p-5'>
        <div className="text-3xl rounded-lg border h-8 w-8">
          <Link onClick={handleGo}> <MdOutlineKeyboardArrowLeft /></Link>
        </div>
  
  
        <div className="main head ">
      <div className=" my-10">
        <div className="title">
          <h3 className="font-bold text-xl ">Privacy Policy for Medixfy</h3>
        </div>
        <div className="head pt-4">
          {/* <h4 className="font-bold">Introduction</h4> */}
          <span className="text-sm">
            Welcome to Medixfy! This Privacy Policy outlines how we collect,
            use, and protect your personal information when you use our
            application and services. By using Medixfy, you agree to the terms
            described in this policy.
          </span>
          <div className="border-b-2 border-gray-700 pt-4 pb-2.5"></div>
        </div>

        <div>
          <h3 className="font-bold py-2.5">1. Information We Collect</h3>
          <ul className="text-sm pl-10 pb-5">
            <li className="list-disc">
              <strong>Personal Information : </strong> When you create an account
              or book an appointment, we may collect information such as your
              name, contact details (phone number, email address), and payment
              information.
            </li>

            <li className="list-disc pt-3">
              <strong>Health Information : </strong>
              Medixfy may collect health-related information, including doctor
              preferences, appointment details, and medical concerns, to provide
              a tailored service.
            </li>
          </ul>
        </div>

        <div>
          <h3 className="font-bold py-2.5">2. How We Use Your Information</h3>
          <ul className="text-sm pl-10 pb-5">
            <li className="list-disc">
              <strong>To Provide Services : </strong> We use your information to
              process appointments, handle payments, and provide customer
              support.
            </li>

            <li className="list-disc pt-3">
              <strong>For Communication : </strong>
              Your contact information may be used to send you updates,
              reminders, and notifications related to your appointments or
              account.
            </li>

            <li className="list-disc pt-3">
              <strong>To Improve Our Services : </strong> We analyze usage
              patterns to enhance user experience and improve service offerings.
            </li>
          </ul>
        </div>

        <div>
          <h3 className="font-bold py-2.5">3. Information Sharing</h3>
          <ul className="text-sm pl-10 pb-5">
            <li className="list-disc">
              <strong>With Doctors : </strong> Your health-related information may
              be shared with doctors as required for consultations.
            </li>

            <li className="list-disc pt-3">
              <strong>With Partners : </strong>
              Certain non-identifiable data may be shared with partners for
              analytics and marketing purposes.
            </li>

            <li className="list-disc pt-3">
              <strong>For Legal Reasons :</strong> If required by law or in
              response to legal requests, we may disclose necessary information.
            </li>
          </ul>
        </div>

        <div>
          <h3 className="font-bold py-2.5">4. Data Security</h3>
          <span className="text-sm">
            We prioritize your data security. Medixfy uses industry-standard
            security measures to protect your data from unauthorized access,
            alteration, or destruction. However, please note that no electronic
            transmission or storage method is 100% secure.
          </span>
        </div>

        <div>
          <h3 className="font-bold py-2.5">5. Retention of Data</h3>
          <span className="text-sm">
            We retain your personal information for as long as your account
            remains active or as necessary to fulfill the purposes described in
            this policy. You can request deletion of your data by contacting us.
          </span>
        </div>

        <div>
          <h3 className="font-bold py-2.5">6. User Rights</h3>
          <span className="text-sm">You have the right to:</span>
          <ul className="text-sm pl-10 pb-5">
            <li className="list-disc">Access your data</li>

            <li className="list-disc pt-3">Request corrections to your data</li>

            <li className="list-disc pt-3">
              Request deletion of your data (subject to legal and contractual
              restrictions)
            </li>

            <li className="list-disc pt-3">Withdraw consent for data usage</li>
          </ul>
        </div>

        <div>
          <h3 className="font-bold py-2.5">7. Changes to Privacy Policy</h3>
          <span className="text-sm">
            Medixfy reserves the right to modify this Privacy Policy at any
            time. We will notify users of significant changes through the app or
            email.
          </span>
        </div>

        <div>
          <h3 className="font-bold py-2.5">8. Contact Us</h3>
          <span className="text-sm">
            For questions or concerns regarding this Privacy Policy, please
            contact us at:
          </span>
        </div>
        <ul className="text-sm pl-10 pb-5">
          <li className="list-disc pt-2">
            <strong>Email : </strong>
            medixfy@gmail.com
          </li>

          <li className="list-disc pt-2">
            <strong>Address : </strong>
            D34, read fm road, D Block, Sector 2, Noida, Uttar Pradesh 201301
          </li>
        </ul>
      </div>
    </div>

      </div>

    </>
  )
}

export default PrivacyPolicy
