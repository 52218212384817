import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { FaRegBell } from "react-icons/fa";
import { MdPhone, MdLocationOn, MdPerson, MdMedicalServices, MdAssignment, MdAccessTime, MdDateRange, MdOutlineCurrencyRupee } from "react-icons/md";
import { getOrderbyId } from '../store/reducer/orderReducer';
import { MdPayment } from "react-icons/md";
import Spinner from '../Layout/Spinner';
import Ticket from "../assets/ticket.jpg"

const AppointmentDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { orderbyid, loader } = useSelector(state => state.order);

  useEffect(() => {
    dispatch(getOrderbyId(id));
  }, [dispatch, id]);

  if (loader) return <Spinner />;

  if (!orderbyid) return <div>No order found.</div>;

  const getStatusBadge = (statusNumber) => {
    switch (statusNumber) {
      case "Pending":
        return (
          <div className="bg-yellow-500 px-2 py-1 text-sm text-center text-white">
            Pending
          </div>
        );
      case "Completed":
        return (
          <div className="bg-green-500 px-2 py-1 text-sm text-center text-white">
            Completed
          </div>
        );
      case "Accepted":
        return (
          <div className="bg-purple-500 px-2 py-1 text-sm text-center text-white">
            Accepted
          </div>
        );
      case "Canceled":
        return (
          <div className="bg-red-500 px-2 py-1 text-sm text-center text-white">
            Canceled
          </div>
        );
      default:
        return <div className="badge badge-ghost">Unknown</div>;
    }
  };

  function  handleprint(id){
    localStorage.setItem("invioceId",id)
    const url = window.location.origin;
    window.open(`${url}/user/invoice`);
    }


  return (
    <div className="min-h-screen flex flex-col items-start justify-center p-4">
      <div className="p-2 rounded-lg w-full max-w-2xl">
        <div className="flex items-center justify-between mb-4">
          <Link to="/orders">
            <IoArrowBack className="text-gray-600 text-xl" />
          </Link>
          <h1 className="text-lg font-semibold text-center text-gray-700 flex-grow">
            Appointment Detail
          </h1>
          {/* <FaRegBell className="text-gray-600 text-xl" /> */}
        </div>


<button className='bg-red-500 text-white rounded-md px-2' onClick={()=>handleprint(orderbyid?._id)}>
  Invoice
</button>
        {/* ticket design */}
        {orderbyid?.status == "Accepted" && (
          <div className="w-100 mb-4 h-[200px]  object-scale-down bg-no-repeat backgr bg-center rounded-lg mx-auto p-4 relative" style={{ backgroundImage: `url(${Ticket})`, backgroundSize: "100%" }}>
            {/* Ticket Title */}
<div className='absolute bottom-6 left-2 '>

          <div className='flex '>
          <h3 className="text-base font-bold ">{orderbyid?.name}</h3>
          <p className='ml-[40px] font-semibold'>{orderbyid?.orderId}</p>
          </div>
            {/* Ticket Price and Details */}
            <div className="flex  items-center mt-8">
                        <p className='font-semibold text-sm'>{orderbyid.date}</p>
                <p className='ml-[80px] font-semibold text-sm'>{orderbyid?.time}</p>
                   </div>

</div>

          </div>

        )}
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Status</h3>
          <span className="text-white px-2 py-1 rounded" >
            {getStatusBadge(orderbyid.status)}
          </span>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
              <MdPerson size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Patient Name</p>
              <p className="text-sm font-semibold">{orderbyid.name}</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
              <MdPhone size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Phone Number</p>
              <p className="text-sm font-semibold">{orderbyid.phone}</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
              <MdLocationOn size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Address</p>
              <p className="text-sm font-semibold">{orderbyid.address}</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
              <MdPayment size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Payment Staus</p>
              <p className="text-sm font-semibold">{orderbyid.payemtStatus}</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
              <MdAssignment size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Problem</p>
              <p className="text-sm font-semibold">{orderbyid.problem}</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
              <MdDateRange size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Date</p>
              <p className="text-sm font-semibold">{orderbyid.date}</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 bg-white p-2 rounded">
            <div className="bg-gray-100 p-4 rounded">
              <MdOutlineCurrencyRupee size={20} className="text-xl text-gray-400" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Amount</p>
              <p className="text-sm font-semibold">{orderbyid.amount}</p>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default AppointmentDetails;
