import { useCallback, useEffect, useState } from 'react'
import { X, Clock } from 'lucide-react'
import { Link, useNavigate } from 'react-router-dom'
import img1 from "../assets/WhatsApp Image 2024-09-24 at 6.57.25 AM.jpeg";
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { getDoctorById } from '../store/reducer/doctorReducer';
import { useDispatch, useSelector } from 'react-redux';
import { payment, paymentSucess, verify } from '../store/reducer/orderReducer';
import { toast } from 'react-toastify';
import { getAdminMoney } from '../store/reducer/adminReducer';
export default function ShoppingCart() {
  const {admindoctorId}=useSelector((state)=>state.doctor)
  const {getAdminmoneyData}=useSelector((state)=>state.admin)
  const [date, setDate] = useState("")
  const navigate=useNavigate()
  const [address,setAddress]=useState([])

const dispatch=useDispatch()



  const getData = useCallback(() => {
  const id=localStorage.getItem("appointment")
      dispatch(getDoctorById(id));   
      dispatch(getAdminMoney())
  }, [ dispatch]);  
  
  
  useEffect(() => {
  getData()    
  const storedData = localStorage.getItem("appointmentData");
setDate(localStorage.getItem("date"))
  // Check if the data exists
  if (storedData) {
    // Parse the JSON string back into an object
    const parsedData = JSON.parse(storedData);
    setAddress(parsedData)
  }
  }, [dispatch ,getData]);


// razorpay
  const handleOpenRazorpay = (data) => {
    const options = {
        key: 'rzp_test_AosXuLCjM1P5LD',
        amount: Number(data.amount+getAdminmoneyData?.amount),
        currency: data.currency,
        order_id: data.id,
        name: 'Medixfy',//
        description: 'This is a doctor website',//
        handler: async function (response) {
            await dispatch(verify({ response: response }))
                .then(res => {
                   dispatch(paymentSucess({name:address.name, phone:address.phone,age:address.age, problem:address.problem, address:address.address, date:date, appointment:admindoctorId._id, payment: data,  }))
                    localStorage.removeItem("ap");                 
                    toast.success("Payment Completed Successfully ");
                    navigate("/orders");

                })
                .catch(err => {
                    console.log(err)
                })
            if (data) {

            } else {
                console.log("err")
            }
        }
    }
    const rzp = new window.Razorpay(options)
    rzp.open()
}

const handlePayments = async (amount) => {

    await dispatch(payment(amount)).then((res) => {
           
            if(res.payload.success){
              toast.success(res.payload.message)
              handleOpenRazorpay(res?.payload.data)
            }else{
              toast.error(res.payload.message)

            }
        })
        .catch(err => {
            console.log(err)
        })
}
//razorpay

  return (
    <div className="  min-h-[100vh] relative">
      <div className="max-w-[450px] min-h-screen pt-4 mx-auto px-4 ">
      <div>
                <div className="text-3xl rounded-lg border h-8 w-8">
                    <Link to='/doctorlist'> <MdOutlineKeyboardArrowLeft /></Link>
                </div>
            </div>
      

      

        <div className="grid md:grid-cols-2 gap-8">
          <div className="md:col-span-2">
            <h1 className="text-2xl font-bold mb-6 mt-5">{admindoctorId?.name}</h1>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="flex gap-6">
                <img src={admindoctorId?.photo} alt="NikeCourt Air Max Volley" className="w-24 h-24 object-cover" />
                <div className="flex-grow">
                  <h3 className="font-semibold">{admindoctorId?.profile}</h3>
                  <p className="text-sm text-gray-600">{admindoctorId?.category}</p>
                  <p className="text-sm text-gray-600">{(admindoctorId?.about)?.match(/^(\S+\s+){0,19}\S+/)}</p>
                  <div className="flex gap-4 mt-2">
                 </div>
                 
                </div>
                <div className="text-right">
                  <p className="font-semibold">₹{admindoctorId?.amount}</p>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <h2 className="font-semibold mb-2">Address</h2>
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <Clock size={16} />
                <p>{address?.address}</p>
                {/* <Link className="underline">Edit Location</Link> */}
              </div>
           
            </div>
         

          
          </div>

          <div className='md:col-span-2'>
            <h2 className="text-xl font-bold mb-6">Summary</h2>
            <div className="bg-white p-6 rounded-lg shadow-sm">
           
              <div className="space-y-4">
                <div className="flex justify-between">
                  <p>Subtotal</p>
                  <p>₹{admindoctorId?.amount}</p>
                </div>
              
                <div className="flex justify-between">
                  <p>Estimated Tax</p>
                  <p>₹{getAdminmoneyData?.amount}</p>
                </div>
                
                <div className="border-t pt-4 flex justify-between font-bold">
                  <p>Total</p>
                  <p>₹{admindoctorId?.amount+getAdminmoneyData?.amount}</p>
                </div>
              </div>
              <button className="w-full bg-black text-white py-4 rounded-full mt-6 font-semibold" onClick={()=>handlePayments(admindoctorId?.amount+getAdminmoneyData?.amount)}>
                Checkout
              </button>
              {/* <button className="w-full border border-gray-300 py-4  font-bold rounded-full mt-4 text-[#112378]">
                PayPal
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
