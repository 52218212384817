import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getAdminDetails } from '../store/reducer/adminReducer';

const AdminPrivate = ({ children }) => {

    const dispatch = useDispatch();
    const location = useLocation();

    const [token, setToken] = useState(true);  // Initialize token state

    // Fetch admin details and set token
    const getData = useCallback(() => {
        dispatch(getAdminDetails()).then((res) => {
            console.log("API response:", res);
            if (res.payload && res.payload.success) {

                const receivedToken = res.payload.data;
                console.log("Setting token:", receivedToken);
                // setToken(receivedToken);
                setToken(true)
            } else {
                console.error("Token data is missing from API response");
                setToken(false)
            }
        }).catch(error => {
            console.error("Error fetching admin details:", error);
        });
    }, [dispatch]);

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        if (token) {
            console.log("Updated token in state:", token);
        } else {
            console.log("Token state is null or empty");
        }
    }, [token]);
    if (token) {
        return children;  // Render children if the token is valid
    }

    return <Navigate to="/adminlogin" state={{ from: location }} replace />;
};

export default AdminPrivate;
