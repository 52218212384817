import React, { useEffect, useState } from "react";
import img1 from "../assets/WhatsApp Image 2024-09-26 at 7.00.14 AM.jpeg";
import img2 from "../assets/WhatsApp Image 2024-09-24 at 5.35.52 AM.jpeg";
import img3 from "../assets/WhatsApp Image 2024-09-26 at 6.59.44 AM.jpeg";
import { IoLocationOutline, IoMenu, IoSearchOutline } from "react-icons/io5";
import { IoIosStar } from "react-icons/io";
import { FaHeart } from "react-icons/fa";
import Footer from "../Layout/Footer";
import { getUserDetails } from "../store/reducer/authReducer";
import { useDispatch, useSelector } from "react-redux";
import profile from "../assets/WhatsApp Image 2024-09-24 at 6.57.25 AM.jpeg";
import { Link, useNavigate } from "react-router-dom";

const array = [
  {
    title: "Doctor Consulation",
    img: img1,
    link:"/location",
  },
  // {
  //   title: "Lab test",
  //  link:"/location",
  //   img: img2,
  // },
 
];
const Home = () => {
const [loader,setLoader]=useState(false)
  const dispatch = useDispatch();
  const {userDetail} = useSelector((state) => state.auth)
  useEffect( () => {
    dispatch(getUserDetails()).then((res)=>{
      if(res.payload.data){
        setLoader(true)
      }
    }).catch((error)=>{
      setLoader(false)
    })
  }, [dispatch])

  return (
    <div>



      <div className="min-h-screen w-full flex flex-col items-start justify-start gap-4 p-5 ">
        <div className=" w-full">
          <div className="flex flex-col justify-between">
            <header className="flex justify-between items-center w-full">
              <img src="/logo.png" alt="" className="w-28" />
              <h1 className="text-lg text-gray-700 font-semibold">Home</h1>
              <div className="w-8 h-8 bg-blue-100 rounded-full overflow-hidden">
                <img src={userDetail?.photo?userDetail?.photo: profile}/>
              </div>
            </header>
            <div className="mt-6">
              <p className="text-gray-500">Hi,  {userDetail?.name ? userDetail.name : "Guest"}</p>
              <h2 className="text-2xl text-gray-700 font-semibold mt-1">
                Welcome Back
              </h2>
            </div>

            <div className="relative mt-3">
              <IoSearchOutline className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search"
                className="w-full py-2 pl-10 pr-4 bg-white rounded-lg border-[0.5px] border-gray-300 text-sm focus:outline-none"
              />
            </div>
          </div>
        </div>


        {array.map((arrays, index) => (
          <div key={index} className="flex flex-col w-full gap-1">
            <div className="w-full h-[250px] rounded-lg overflow-hidden relative">
              <div className="text-2xl absolute right-2 top-2 text-[white] after:text-red-600">
                <FaHeart />
              </div>
              <img
                src={arrays.img}
                alt="image"
                className=" object-cover h-full w-full"
              />
            </div>
            <h2 className="text-[18px] font-[500]">{arrays.title}</h2>
            <button className="flex-1 border border-blue-500 text-blue-500 py-2 rounded-full text-sm font-semibold"  >
            <Link to={arrays?.link}>
                BOOK NOW
            </Link>
              </button>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
