import React, { useCallback, useEffect } from 'react'
import { IoArrowBack, IoSearch, IoOptions } from 'react-icons/io5'
import {
    FaTooth,
    FaBrain,
    FaBone,
    FaEye,
    FaToilet
} from 'react-icons/fa'
import { GiLiver, GiKidneys, GiStomach, GiMuscularTorso } from 'react-icons/gi'
import { BsHeart } from "react-icons/bs";
import { FaHeartbeat } from "react-icons/fa";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Footer from '../Layout/Footer'
import { Link } from 'react-router-dom';
import { CiLocationOn } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux';
import { getAllCity } from '../store/reducer/doctorReducer';

const Citys = [
    { name: 'Noida', icon: BsHeart },
    { name: 'Delhi', icon: GiStomach },
    { name: 'noida', icon: FaTooth },
    { name: 'delhi', icon: FaBrain },
    { name: 'Kidney', icon: GiKidneys },
    { name: 'Heart', icon: FaHeartbeat },
    { name: 'Bones', icon: FaBone },
    { name: 'Liver', icon: GiLiver },
    { name: 'Muscles', icon: GiMuscularTorso },
    { name: 'Bowel', icon: FaToilet },
    { name: 'Eye', icon: FaEye },
]
const City = () => {
    const {cityData}=useSelector((state)=>state.doctor)
const dispatch=useDispatch()

const getData=useCallback(()=>{
dispatch(getAllCity())
},[dispatch])

useEffect(() => {
    getData()
}, [getData]);

    return (
        <div className=" flex flex-col justify-between min-h-screen">
            <div className="w-full overflow-hidden  p-2 ">
                <div className="p-4">
                    <div className="flex items-center justify-between mb-4 sticky bg-[#fafafa] top-0 z-20">
                        <Link to='/'><IoArrowBack className="text-gray-600 text-xl" /></Link>
                        <h1 className="text-lg font-semibold text-center flex-grow">Find your neer by location</h1>
                        <HiOutlineDotsVertical className="text-gray-600 text-xl" />
                    </div>
                    <div className="relative mb-6">
                        <IoSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                        <IoOptions className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                        <input
                            type="text"
                            placeholder="Search"
                            className="w-full pl-10 pr-4 py-2 border bg-white border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <h2 className="text-lg font-semibold mb-4">Search By City</h2>
                    <div className="grid grid-cols-4 gap-3">
                        {Array.isArray(cityData) && cityData.map((category, index) => (
                            <Link
                                key={index}
                                className="col-span-1 text-gray-500 bg-white p-4 group hover:bg-[#3c8aff] hover:text-white hover:bgs:text-white"
                                to={`/doctorlist?city=${category.slug}`}
                            >
                                <div className='flex flex-col items-center justify-center'>
                                    <div
                                        className="h-10 w-10 bg-[#eef4f8] rounded-lg flex items-center justify-center mb-2"
                                    >
                                        {/* The 'group-hover' utility applies hover styles when the parent is hovered */}
                                        <CiLocationOn className='bgs text-gray-500 group-hover:text-black transition-colors duration-300' />
                                    </div>
                                    <p className="text-xs text-center">{category.name}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default City
