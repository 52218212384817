// propertySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import Cookies from "js-cookie";
import { api } from "../api";

export const payment = createAsyncThunk(
  "auth/payment",
  async (amount, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(
        "/payment",
        { amount: amount },
        { withCredentials: true }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const verify = createAsyncThunk(
  "auth/verify",
  async (response, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post("/verify", response, {
        withCredentials: true,
      });

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const paymentSucess = createAsyncThunk(
  "auth/verify-payment",
  async (
    { name, phone, problem, address, date, appointment, payment },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { data } = await api.post(
        "/payment/success",
        { name, phone, problem, address, date, appointment, payment },
        { withCredentials: true }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createOrderAgain = createAsyncThunk(
  "auth/order-again",
  async (
    { orderNo},
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { data } = await api.post(
        "/order-again",
        {orderNo },
        { withCredentials: true }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const showOrder = createAsyncThunk(
  "auth/get-order",
  async ({orderId, page, limit}, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/get-order?orderId=${orderId}&page=${page}&limit=${limit}`, { withCredentials: true });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getOrderbyId = createAsyncThunk(
  "auth/get-order-by-id",
  async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/get-order-by-id/${id}`, {
        withCredentials: true,
      });

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getOrderbyDoctor = createAsyncThunk(
  "auth/get-order-by-doctor",
  async ({orderId, page, limit,docid}, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/get-order-by-doctor/${docid}/?orderId=${orderId}&page=${page}&limit=${limit}`, {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getpendingOrder = createAsyncThunk(
  "auth/get-pending-order-by-doctor",
  async ({orderId, page, limit,docid}, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/get-pending-order-by-doctor/${docid}/?orderId=${orderId}&page=${page}&limit=${limit}`,  {
        withCredentials: true,
      });

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRepeateOrder = createAsyncThunk(
  "auth/gget-pending-repeate-order-by-doctor",
  async ({orderId, page, limit,docid}, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/get-pending-repeate-order-by-doctor/${docid}/?orderId=${orderId}&page=${page}&limit=${limit}`,  {
        withCredentials: true,
      });

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateOrderbyId = createAsyncThunk(
  "auth/update-order-by-id",
  async ({ OrderId,time, status }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(`/update-order-by-id/${OrderId}`, {
      time:time,  status: status,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteOrderById = createAsyncThunk(
  "auth/delete-order-by-id",
  async (OrderId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.delete(`/delete-order-by-id/${OrderId}`);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminAllOrders = createAsyncThunk(
  "auth/admin/get-all",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/admin/get-all`,{withCredentials:true});
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllOrders = createAsyncThunk(
  "/admin/get-all-order",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/admin/get-all-order`,{withCredentials:true});
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllOrdersData = createAsyncThunk(
  "order/get-all-order-data",
  async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/doctor/get-all-data/${id}`,{withCredentials:true});
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const orderReducer = createSlice({
  name: "order",
  initialState: {
    loader: false,
    errorMessage: "",
    successMessage: "",
    pendingorderRepeate: false,
    orderDetail: null, // add userDetail to initialState
  },
  reducers: {
    setPendingOrderRepeate: (state, action) => {
      state.pendingorderRepeate = action.payload;
      state.pendingorder = action.payload;
      state.orderdoctorbyid = action.payload;
    },
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
    order_reset: (state) => {
      state.orderInfo = "";
      state.pendingorderRepeate=""
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(payment.pending, (state) => {
        state.loader = true;
      })
      .addCase(payment.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(payment.fulfilled, (state, { payload }) => {
        state.payload = payload;
        state.successMessage = payload.message;
        state.loader = false;
      })
      .addCase(verify.pending, (state) => {
        state.loader = true;
      })
      .addCase(verify.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(verify.fulfilled, (state, { payload }) => {
        state.payload = payload;
        state.successMessage = payload.message;
        state.loader = false;
      })
      .addCase(paymentSucess.pending, (state) => {
        state.loader = true;
      })
      .addCase(paymentSucess.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(paymentSucess.fulfilled, (state, { payload }) => {
        state.payload = payload;
        state.successMessage = payload.message;
        state.loader = false;
      })

      .addCase(createOrderAgain.pending, (state) => {
        state.loader = true;
      })
      .addCase(createOrderAgain.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(createOrderAgain.fulfilled, (state, { payload }) => {
        state.payload = payload;
        state.successMessage = payload.message;
        state.loader = false;
      })

      .addCase(showOrder.pending, (state) => {
        state.loader = true;
      })
      .addCase(showOrder.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(showOrder.fulfilled, (state, { payload }) => {
        // Assuming the response structure has `data` and `message` fields
        const orderdata = payload?.data; // If `payload` is undefined, ensure safe access
        state.successMessage =
          payload?.message || "Order retrieved successfully";
        state.loader = false;
        state.orderdata = orderdata || []; // Default to an empty array if no data
      })

      .addCase(getOrderbyId.pending, (state) => {
        state.loader = true;
      })
      .addCase(getOrderbyId.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getOrderbyId.fulfilled, (state, { payload }) => {
        let orderbyid = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.orderbyid = orderbyid;
      })
      .addCase(getOrderbyDoctor.pending, (state) => {
        state.loader = true;
      })
      .addCase(getOrderbyDoctor.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getOrderbyDoctor.fulfilled, (state, { payload }) => {
        let orderdoctorbyid = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.orderdoctorbyid = orderdoctorbyid;
      })
      .addCase(getpendingOrder.pending, (state) => {
        state.loader = true;
      })
      .addCase(getpendingOrder.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getpendingOrder.fulfilled, (state, { payload }) => {
        let pendingorder = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.pendingorder = pendingorder;
      })
      .addCase(getRepeateOrder.pending, (state) => {
        state.loader = true;
      })
      .addCase(getRepeateOrder.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getRepeateOrder.fulfilled, (state, { payload }) => {
        let pendingorderRepeate = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.pendingorderRepeate = pendingorderRepeate;
      })

      // update order status
      .addCase(updateOrderbyId.pending, (state) => {
        state.loader = true;
      })
      .addCase(updateOrderbyId.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(updateOrderbyId.fulfilled, (state, { payload }) => {
        let orderbyid = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.orderbyid = orderbyid;
      })
      // delete order
      .addCase(deleteOrderById.pending, (state) => {
        state.loader = true;
      })
      .addCase(deleteOrderById.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(deleteOrderById.fulfilled, (state, { payload }) => {
        state.successMessage = payload.message;
        state.loader = false;
        state.orders = state.orders.filter(
          (order) => order._id !== payload.data._id
        );
      })
       // all orders datas
       .addCase(adminAllOrders.pending, (state) => {
        state.loader = true;
      })
      .addCase(adminAllOrders.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(adminAllOrders.fulfilled, (state, { payload }) => {
        let adminAllOrdersData = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.adminAllOrdersData = adminAllOrdersData;
      })
       //get all orders
       .addCase(getAllOrders.pending, (state) => {
        state.loader = true;
      })
      .addCase(getAllOrders.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getAllOrders.fulfilled, (state, { payload }) => {
        let allOrderData = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.allOrderData = allOrderData;
      })
       //get all orders data of doctor
       .addCase(getAllOrdersData.pending, (state) => {
        state.loader = true;
      })
      .addCase(getAllOrdersData.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getAllOrdersData.fulfilled, (state, { payload }) => {
        let allOrderDataDoctor = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.allOrderDataDoctor = allOrderDataDoctor;
      })
  },
});

export const { messageClear, setPendingOrderRepeate,order_reset } = orderReducer.actions;
export default orderReducer.reducer;
