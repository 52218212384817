import React, { useState } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { optSend } from "../store/reducer/authReducer";

const ForgetPass = () => {
  const [phone,setPhone]=useState("")
const dispatch=useDispatch()
const navigate=useNavigate()

const handleSubmit=async()=>{

 await dispatch(optSend(phone)).then((res)=>{

  if(res?.payload?.success){
    toast.success(res.payload.message);
    setTimeout(() => {
      navigate("/otp")
    }, 1000);
 
  }else{
    toast.error(res.payload.message);
  }
 })
}

  return (
    <div className="h-[100vh] p-5 relative">
    <div>
      <div className="text-3xl rounded-lg border h-8 w-8">
      <Link to='/login'> <MdOutlineKeyboardArrowLeft /></Link>
      </div>
      </div>
      <div className="flex items-start flex-col h-[80%] gap-5 py-10">
        <h2 className="text-3xl font-semibold text-left">
          Forget Password ?
        </h2>
        <p>Don't worry! it occures. Please enter the Phone Number to send the code.</p>

        <form className="flex flex-col items-center w-full mt-10">
          <input
            type="number"
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            placeholder="Enter your Mobile Number"
            className="mb-4 p-4 border-[0.5px] border-border bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
          />
          <Link onClick={handleSubmit} className="bg-[#4486ff] text-white p-4 rounded-lg w-full text-center mt-5">
            Send Code
          </Link>
        </form>
      </div>
      <p className="text-sm text-center font-[400] absolute bottom-3 left-1/2 transform -translate-x-1/2 w-full">Remember Password? <span className="text-[#37C2C1]"><Link to="/login">Login</Link></span></p>
    </div>
  )
}

export default ForgetPass
