import React , { useState, useEffect, useMemo, useCallback } from "react";

import { Navigate, Outlet, useNavigate } from "react-router-dom";

import Spinner from "./Spinner";

import { useDispatch, useSelector } from "react-redux";
import {  getUserDetails,  } from "../store/reducer/authReducer";

const getCookieValue = (cookieName) => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
      const [key, value] = cookie.split("=");
      if (key === cookieName) {
        return decodeURIComponent(value);
      }
    }
    return null; // Return null if the cookie is not found
  };
  
  // Usage
 

export default function PrivateRoute() {
    const dispatch = useDispatch();
    const navigate=useNavigate()
    const { userDetail } = useSelector((state) => state.auth);
    const [ok, setOk] = useState(true);
    const myCookie = getCookieValue("token");

    const processedUserInfo = useMemo(() => {
        return userDetail ? userDetail : null;
    }, [userDetail]);
    useEffect(() => {
        if (!processedUserInfo) {
            dispatch(getUserDetails()).then((res)=>{
              
                if(res.payload.success===false){
                    navigate('/login')
                }
            });
        }
        
    }, [dispatch, processedUserInfo]);
    useEffect(() => {
        if(getCookieValue("token") !==null || !!processedUserInfo){
            setOk(true)
        }else{
            setOk(false)
        }
   
    }, [processedUserInfo])
   
    return ok ? <Outlet /> : <Navigate to={"/login"} />;
}