import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminMoney } from "../../../main/store/reducer/adminReducer";
import { getOrderbyId } from "../../../main/store/reducer/orderReducer";
import domtoPDF from "dom-to-pdf";
import { IoArrowBack } from "react-icons/io5";
import { FaFileDownload } from "react-icons/fa";
import "../../../App.css"
import { Link } from "react-router-dom";
const thanku = "https://i.ibb.co/cgJzPKD/thanks.jpg";
const Invoice = () => {
  const { getAdminmoneyData } = useSelector((state) => state.admin)
  const { orderbyid } = useSelector((state) => state.order)

  const dispatch = useDispatch()
  const getData = useCallback(() => {
    dispatch(getAdminMoney())
    dispatch(getOrderbyId(localStorage.getItem("invioceId")));
  }, [dispatch]);

  useEffect(() => {
    getData()
  }, [getData]);



  function formatDate(isoString) {
    const date = new Date(isoString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }


  // Output: "2024-11-18 10:52:13"

  const contentRef = useRef();

  const handleDownloadPdf = () => {
    const element = contentRef.current;
    const options = {
      filename: 'invoice.pdf',
      jsPDF: { format: 'a4', orientation: 'portrait' },
      margin: 0,
      scale: 2,  // Higher scale for better quality
      onclone: (doc) => {
        // Ensure the cloned element retains styles
        doc.querySelector('.invoice-container').style.boxShadow = 'none';
      }
    };

    domtoPDF(element, options, () => {
      console.log('PDF generated successfully!');
    });
  };


  return (

    <>
      <div className="max-w-md mx-auto  bg-white pt-2">
        <div className="flex items-center justify-between mb-4 px-8">
          <Link to="/orders">
            <IoArrowBack className="text-gray-600 text-xl" />
          </Link>
          <h1 className="text-lg font-semibold text-gray-700 text-center flex-grow">
            Invoice
          </h1>
          <FaFileDownload className="text-gray-600 text-xl" onClick={handleDownloadPdf} />
        </div>

        <div ref={contentRef} className="mt-20 px-8">
          <div className="flex items-center">
            {/* Horizontal line */}
            <div className="border-t border-black flex-grow"></div>

            {/* Text at the end of the line */}
            <h1 className="ml-4 text-3xl uppercase">Invoice</h1>
          </div>

          {/* Issued To  */}
          <div className="mt-28 flex justify-between">
            {/* Left Section */}
            <div>
              <h3 className="uppercase  font-medium">Issued to:</h3>
              <span className="text-sm">
                Garhi, Jamui, Bihar, India, 811317 <br />
                (Branch) <br />
                D34, Red FM Road, DBlock, Sector2, <br />
                Noida, Uttar Pradesh 201301
              </span>
            </div>

            {/* Right Section */}
            <div>
              <h3 className="mr-3 uppercase font-medium">Invoice no:</h3><br />
              <span className="font-bold uppercase">{orderbyid?.orderId}</span>
            </div>
          </div>

          {/* Description */}

          <div className="mt-20">
            <div className="flex justify-between items-center">
              {/* Left Section */}
              <h3 className="uppercase font-medium">Description:</h3>

              {/* Right Section */}
              <h3 className="text-right uppercase font-medium">Total</h3>
            </div>

            {/* Underline Spanning Entire Row */}
            <div className="border-b border-black mt-2"></div>

            {/* Content Below */}
            <div className="mt-4">
              <div className="flex justify-between">
                <span>Doctor Fees</span> <br />
                <h4 className="font-semibold">₹{orderbyid?.amount}/-</h4>
              </div>
              <div className="flex justify-between">
                <span>Platform charge</span>
                <h4 className="font-semibold">₹{getAdminmoneyData?.amount}/-</h4>
              </div>
            </div>
          </div>

          {/* last total section */}

          <div className="border border-black mb-3 mt-20"></div>
          <div className="flex justify-between">
            <h3 className="uppercase font-medium">Total</h3>
            <h4 className="font-semibold">₹{getAdminmoneyData?.amount + orderbyid?.amount}/-</h4>
          </div>
          <h3 className="uppercase font-medium mt-10">Date</h3>
          <h4 className="font-semibold text-sm">{formatDate(orderbyid?.createAt)}</h4>

          <div className="flex justify-end mt-12">
            <img src={thanku} alt="thankyou" className="w-32" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
