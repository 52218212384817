import React, { useState } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { register } from "../store/reducer/authReducer";
import { toast } from 'react-toastify';

const Register = () => {
  const { loader } = useSelector((state) => state.auth)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [cpassword, setCPassword] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append("name", name)
    formData.append("phone", phone)
    formData.append("password", password)
    formData.append("cpassword", cpassword)
    if (isChecked){
      await dispatch(register(formData)).then((res) => {

        if (res?.payload?.success) {
          toast.success(res.payload.message);
          navigate("/login")

        } else {

          toast.error(res.payload.message);
        }
      })
    }else{
      toast.error("Please check privacy");
    }

  }




  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showPassword2, setShowPassword2] = useState(false);
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };



  return (
    <div className="h-[100vh] p-5 relative">
      <div>
        <div className="text-3xl rounded-lg border h-8 w-8">
          <Link to='/'> <MdOutlineKeyboardArrowLeft /></Link>
        </div>
      </div>
      <div className="flex items-center flex-col h-[80%] gap-5 py-10">
        <h2 className="text-3xl font-semibold">
          Welcome Back! Glad to See You Again.
        </h2>

        <form className="flex flex-col items-center w-full mt-2">
          <input
            type="text"
            placeholder="Username"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mb-4 p-4 border-[0.5px] border-border bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
          />
          <input
            type="number"
            placeholder="Mobile Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="mb-4 p-4 border-[0.5px] border-border bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
          />
          <div className="relative w-full mb-4">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="p-4 border-[0.5px] border-border bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
            />
            <button
              type="button"

              onClick={togglePasswordVisibility}
              className="absolute text-gray-400 text-xl right-3 top-1/2 transform -translate-y-1/2 "
            >
              {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
          <div className="relative w-full mb-4">
            <input
              type={showPassword2 ? "text" : "password"}
              placeholder="Confirm Password"
              value={cpassword}
              onChange={(e) => setCPassword(e.target.value)}
              className="p-4 border-[0.5px] border-border bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility2}
              className="absolute text-gray-400 right-2 top-4 text-muted-foreground text-xl"
            >
              {showPassword2 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>

          <div className='flex items-center mt-0 ms-[-30px]'>
            <label className="flex items-center ">
              <input
                type="checkbox"
                className="hidden peer"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <div className="w-6 h-6 rounded-full border-2 border-gray-300 flex items-center justify-center peer-checked:border-blue-500 peer-checked:bg-blue-500">
                <svg
                  className={`w-4 h-4 text-white ${isChecked ? 'block' : 'hidden'}`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-7.5 7.5a1 1 0 01-1.414 0l-3.5-3.5a1 1 0 111.414-1.414L8 11.586l6.793-6.793a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <span className="gray-100 ms-2 mr-2 text-sm cursor-pointer">I have read and agree</span> <Link className='color-red-200' to={'/privacy-policy'}>[Privacy Agreement]</Link>
            </label>
          </div>
          <Link className="bg-[#4486ff] text-white p-4 rounded-lg w-full text-center  mt-5" onClick={handleSubmit}>
            Register
          </Link>
        </form>
      </div>
      <p className="text-sm text-center font-[400] absolute bottom-3 left-1/2 transform -translate-x-1/2 w-full">Already have an account? <span className="text-[#37C2C1]"><Link to='/login'>Login here</Link></span></p>
    </div>
  )
}

export default Register
