// propertySlice.js
import { api } from "../api";
import Cookies from 'js-cookie';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
;


export const adminLogin = createAsyncThunk(
  '/admin/login',
  async (formData, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post('/admin/login', formData);
      // Cookies.set('token', data?.data?.token, { secure: true, sameSite: 'None' });      

      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 5);
      
      // Set the cookie with the specified expiration date
      Cookies.set("token", data?.data?.token, {
        secure: true,
        sameSite: "None",
        expires: expirationDate
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAdminDetails = createAsyncThunk(
  'auth/admin-me',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get('/admin-me',{withCredentials:true});
  
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



// Admin Controller add doctor in admin
export const addCategory = createAsyncThunk(
  '/admin/add-category',
  async (formData, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(`/admin/add-category`,  formData, {withCredentials:true});
      
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const showCategory = createAsyncThunk(
  '/admin/get-category',
  async ( _ , { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/admin/get-category`, {withCredentials:true});
      
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteCategory = createAsyncThunk(
  'auth/admin/delete-category',
  async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.delete(`/admin/delete-category/${id}`);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateCategory = createAsyncThunk(
  'auth/admin/update-category',
  async ({ id, formData }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.put(`/admin/update-category/${id}`, formData);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addProfile = createAsyncThunk(
  '/admin/update-profile',
  async (formData, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.put(`/update-profile`,  formData, {withCredentials:true});
      
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getAdminMoney = createAsyncThunk(
  'auth/get-admin',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get('/get-admin',{withCredentials:true});
  
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const adminReducer = createSlice({
  name: 'admin',
  initialState: {
    loader: false,
    errorMessage: '',
    successMessage: '',
    adminDetail: null, // add userDetail to initialState
    getAdminDetailData: null,
  },
  reducers: {
    messageClear: (state) => {
      state.errorMessage = '';
      state.successMessage = '';
    },
    user_reset: (state) => {
      state.adminInfo = '';
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(adminLogin.pending, (state) => {
      state.loader = true;
    })
    .addCase(adminLogin.rejected, (state, { payload }) => {
      // console.log('adminLogin rejected payload:', payload); // Log payload
      state.errorMessage = payload?.error || 'An error occurred';
      state.loader = false;
    })
    .addCase(adminLogin.fulfilled, (state, { payload }) => {
       state.payload=payload;
      state.successMessage = payload.message;
      state.loader = false;
    })
  
      .addCase(addCategory.pending, (state) => {
        state.loader = true;
      })
      .addCase(addCategory.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || 'An error occurred';
        state.loader = false;
      })
      .addCase(addCategory.fulfilled, (state, { payload }) => {
         let admincategorydata=payload.data;
         state.successMessage = payload.message;
         state.loader = false;
         state.admincategorydata=admincategorydata;
      })
      .addCase(addProfile.pending, (state) => {
        state.loader = true;
      })
      .addCase(addProfile.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || 'An error occurred';
        state.loader = false;
      })
      .addCase(addProfile.fulfilled, (state, { payload }) => {
         let addprofiledata=payload.data;
         state.successMessage = payload.message;
         state.loader = false;
         state.addprofiledata=addprofiledata;
      })
    //   SHow 
      .addCase(showCategory.pending, (state) => {
        state.loader = true;
      })
      .addCase(showCategory.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || 'An error occurred';
        state.loader = false;
      })
      .addCase(showCategory.fulfilled, (state, { payload }) => {
         let admincategorydata=payload.data;
         state.successMessage = payload.message;
         state.loader = false;
         state.admincategorydata=admincategorydata;
      })
       // delete category id
       .addCase(deleteCategory.pending, (state) => {
        state.loader = true;
      })
      .addCase(deleteCategory.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || 'An error occurred';
        state.loader = false;
      })
      .addCase(deleteCategory.fulfilled, (state, { payload }) => {
        let deletecategoryId = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.deletecategoryId = deletecategoryId;
      })
      // 
       .addCase(updateCategory.pending, (state) => {
        state.loader = true;
      })
      .addCase(updateCategory.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || 'An error occurred';
        state.loader = false;
      })
      .addCase(updateCategory.fulfilled, (state, { payload }) => {
        let admincategorydata = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.admincategorydata = admincategorydata;
      })
      .addCase(getAdminDetails.pending, (state) => {
        state.loader = true;
      })
      .addCase(getAdminDetails.rejected, (state) => {
        state.loader = false;
      })
      .addCase(getAdminDetails.fulfilled, (state, { payload }) => {
        state.successMessage = payload.message;
        state.loader = false;
        state.getAdminDetailData = payload.data; // Make sure you store payload.data correctly
      })

      .addCase(getAdminMoney.pending, (state) => {
        state.loader = true;
      })
      .addCase(getAdminMoney.rejected, (state) => {
        state.loader = false;
      })
      .addCase(getAdminMoney.fulfilled, (state, { payload }) => {
        state.successMessage = payload.message;
        state.loader = false;
        state.getAdminmoneyData = payload.data; // Make sure you store payload.data correctly
      })
     
     
  }
});

export const { messageClear, doctor_reset } = adminReducer.actions;
export default adminReducer.reducer;
