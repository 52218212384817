import React from 'react'
import { Link } from "react-router-dom"
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
const AboutUs = () => {
  return (
    <>
      <div className='p-5 h-min-full'>
        <div className="text-3xl rounded-lg border h-8 w-8">
          <Link to='/about'> <MdOutlineKeyboardArrowLeft /></Link>
        </div>
        <div className="head ">
          <div className=" my-10">
            <div className="title">
              <h3 className="pb-2.5 font-bold">About Us</h3>
            </div>
            <div>
              <span className="text-sm">
                Welcome to Medixfy! We’re here to make healthcare easy and
                accessible, giving you direct access to the doctors and health
                services you need—all in one place.
              </span>
            </div>
            <div>
              <h3 className="font-bold py-2.5">Our Story</h3>
              <span className="text-sm">
                Medixfy started with a simple idea: make it easier for people to
                connect with trusted doctors and manage their health without all the
                usual hassles. We know life gets busy, and finding time to book
                appointments, keep track of health records, and search for the right
                specialists can be overwhelming. That’s where we come in.
              </span>
            </div>

            <div>
              <h3 className="font-bold py-2.5">What We Do</h3>
              <span className="text-sm">
                Think of Medixfy as your personal healthcare assistant. Need a
                doctor? We help you find one that suits your specific needs. Want to
                keep your health records organized? We’ve got you covered.
                Everything you need for your health journey is just a few clicks
                away.
              </span>
            </div>

            <div>
              <h3 className="font-bold py-2.5">Why Medixfy?</h3>
              <ul className="text-sm pl-10">
                <li className="list-disc pt-3">
                  <strong>Simple and Convenient:</strong>
                  No more waiting on hold or searching through endless directories.
                  With Medixfy, booking appointments and accessing services is fast
                  and easy
                </li>
                <li className="list-disc pt-3">
                  <strong>Trusted Doctors :</strong> &nbsp;
                  We partner with certified, experienced doctors from various
                  specialties, so you know you’re in good hands.
                </li>
                <li className="list-disc pt-3">
                  <strong>Your Privacy Matters :</strong> &nbsp;
                  We understand that your health information is personal, and we go
                  to great lengths to keep it safe and secure.
                </li>
                <li className="list-disc pt-3">
                  <strong>Support When You Need It :</strong> &nbsp;
                  Got a question or need help? Our team is here to support you every
                  step of the way.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="font-bold py-2.5">Our Promise to You</h3>
              <span className="text-sm">
                We’re committed to making your healthcare journey as smooth and
                stress-free as possible. Whether it’s finding the right doctor,
                managing your health records, or just making life a little easier,
                Medixfy is here for you.
              </span>
            </div>

            <div>
              <h3 className="font-bold py-2.5">Let’s Make Healthcare Simple</h3>
              <span className="text-sm">
                Thanks for choosing Medixfy! We’re excited to be part of your
                healthcare journey and look forward to helping you live a healthier,
                happier life.
              </span>
            </div>

            <div>
              <h3 className="font-bold py-2.5">Get in Touch</h3>
              <span className="text-sm">
                Have questions or just want to say hi? We’d love to hear from you!
              </span>
            </div>
            <ul className="text-sm pl-10 pb-5">
              <li className="list-disc pt-2">
                <strong>Email : </strong>
                medixfy@gmail.com
              </li>

              <li className="list-disc pt-2">
                <strong>Address : </strong>
                D34, read fm road, D Block, Sector 2, Noida, Uttar Pradesh 201301
              </li>
            </ul>
          </div>
        </div>
      </div>

    </>
  )
}

export default AboutUs
