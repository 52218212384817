import React, { useState } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetePassword } from "../store/reducer/authReducer";
import { toast } from "react-toastify";

export const NewPassword = () => {
  const [password,setPassword]=useState("")
  const [cpassword,setCPassword]=useState("")
  const dispatch=useDispatch()
  const navigate=useNavigate()
  
  const handleSubmit=async()=>{
  
   await dispatch(resetePassword({password,cpassword})).then((res)=>{
  
    if(res?.payload?.success){
      toast.success(res.payload.message);
      setTimeout(() => {
        navigate("/passchanged")
      }, 1000);
   
    }else{
      toast.error(res.payload.message);
    }
   })
  }
  return (
    <div className="h-[100vh] p-5 relative">
    <div>
      <div className="text-3xl rounded-lg border h-8 w-8">
      <Link to='/'> <MdOutlineKeyboardArrowLeft /></Link>
      </div>
      </div>
      <div className="flex items-start flex-col h-[80%] gap-5 py-10">
        <h2 className="text-3xl font-semibold">
          Create a New Password
        </h2>
        <p>Your new password must be unique from the previously used password</p>

        <form className="flex flex-col items-center w-full mt-10">
          <input
            type="password"
            required
            placeholder="New Password"
            value={password}
            onChange={(e)=>setPassword(e.target.value)}
            className="mb-4 p-4 border-[0.5px] border-border bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
          />
          <input
            type="password"
            required
            value={cpassword}
            onChange={(e)=>setCPassword(e.target.value)}
            placeholder="Confirm Password"
            className="mb-4 p-4 border-[0.5px] border-border bg-white rounded-md w-full focus:border-gray-300 focus:outline-none"
          />
          <Link onClick={handleSubmit} className="bg-[#4486ff] text-white p-4 rounded-lg w-full text-center  mt-5">
            Reset Password
          </Link>
        </form>
      </div>
    </div>
  )
}
