import React, { useState, useRef, useEffect } from "react";
import {
  format,
  addMonths,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameDay,
  isBefore,
  isToday,
} from "date-fns";

const DatePicker = ({ onDateSelect }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const scrollRef = useRef(null);
  const today = new Date(); // Current date

  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);
  
  // Filter out dates that are before today
  const futureDays = eachDayOfInterval({ start: today, end: monthEnd });

  // Scroll to the selected date
  useEffect(() => {
    if (scrollRef.current && selectedDate) {
      const selectedElement = scrollRef.current.querySelector(
        `[data-date="${selectedDate.toISOString()}"]`
      );
      if (selectedElement) {
        const scrollContainer = scrollRef.current;
        const scrollContainerRect = scrollContainer.getBoundingClientRect();
        const selectedElementRect = selectedElement.getBoundingClientRect();

        const scrollLeft =
          selectedElementRect.left -
          scrollContainerRect.left +
          scrollContainer.scrollLeft -
          scrollContainerRect.width / 2 +
          selectedElementRect.width / 2;
        scrollContainer.scrollTo({ left: scrollLeft, behavior: "smooth" });
      }
    }
  }, [selectedDate]);

  const handleMonthChange = (event) => {
    setCurrentMonth(new Date(event.target.value));
  };

  const handleDateSelect = (day) => {
    setSelectedDate(day);
    const formattedDate = format(day, "yyyy-MM-dd"); // Format the date
    onDateSelect(formattedDate); // Send selected date to the parent component
  };

  return (
    <div className="w-full mx-auto overflow-hidden">
      {/* Month Selector */}
      <div className="p-1 border-b">
        <div className="relative">
          {/* <select
            value={format(currentMonth, "yyyy-MM")}
            onChange={handleMonthChange}
            className="w-full text-base text-gray-400 font-semibold appearance-none bg-transparent p-1 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            {Array.from({ length: 12 }, (_, i) =>
              addMonths(new Date(), i - 6)
            ).map((date) => (
              <option key={date.toISOString()} value={format(date, "yyyy-MM")}>
                {format(date, "MMMM yyyy")}
              </option>
            ))}
          </select> */}
          {/* <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 pointer-events-none" /> */}
        </div>
      </div>

      {/* Days Grid */}
      <div className="p-2">
        <div
          ref={scrollRef}
          className="flex overflow-x-auto pb-2 scrollbar-hide"
          style={{
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          {futureDays.map((day) => (
            <button
              key={day.toISOString()}
              onClick={() => handleDateSelect(day)} // Call the handler when date is selected
              data-date={day.toISOString()}
              className={`flex-shrink-0 w-16 h-20 mx-1 rounded-lg flex flex-col items-center justify-center
                ${isSameDay(day, selectedDate)
                  ? "bg-[#1e78c0] text-white"
                  : isToday(day)
                  ? "bg-green-500 text-white" // Highlight today
                  : "bg-gray-100 text-gray-400"
                }`}
              style={{ minWidth: "64px", minHeight: "80px" }} // Ensure buttons have fixed size
            >
              <span className="text-xl font-semibold">{format(day, "d")}</span>
              <span className="text-sm font-[400]">{format(day, "EEE")}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
