import React, { useRef, useState } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { optverify } from "../store/reducer/authReducer";
import { toast } from "react-toastify";

export const Otp = () => {
  const [otps, setOtp] = useState(new Array(4).fill(""));
const dispatch=useDispatch()
const navigate=useNavigate()
const inputRefs = useRef([]);

const handleSubmit=async()=>{
const otp=otps.join('')
 await dispatch(optverify(otp)).then((res)=>{

  if(res?.payload?.success){
    toast.success(res.payload.message);
    setTimeout(() => {
      navigate("/newpassword")
    }, 1000);
 
  }else{
    toast.error(res.payload.message);
  }
 })
}





const handleChange = (e, index) => {
  const value = e.target.value;

  if (/^\d$/.test(value)) { // Only allow a single digit
    const otpArray = [...otps];
    otpArray[index] = value;
    setOtp(otpArray);

    // Move to the next input if the current one is filled
    if (index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  }
};

const handleKeyDown = (e, index) => {
  const value = e.target.value;

  if (e.key === "Backspace") {
    const otpArray = [...otps];
    otpArray[index] = ""; // Clear the current input

    // Move to the previous input if backspace is pressed and the current field is empty
    if (index > 0 && value === "") {
      inputRefs.current[index - 1].focus();
    }
    setOtp(otpArray); // Update the state to reflect the change
  }
};

  return (
    <div className="h-[100vh] p-5 relative">
    <div>
      <div className="text-3xl rounded-lg border h-8 w-8">
      <Link to='/forget'> <MdOutlineKeyboardArrowLeft /></Link>
      </div>
      </div>
      <div className="flex items-start flex-col h-[80%] gap-5 py-10">
        <h2 className="text-3xl font-semibold text-left">
          OTP Varification
        </h2>
        <p>Enter Varification code. We just send it on your email</p>

        <form className="flex flex-col items-center w-full mt-10">
        <div className="flex justify-around mb-6 w-full">
        {[...Array(4)].map((_, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          type="text"
          required
          className="w-14 h-14 text-2xl text-center border-[0.5px] border-gray-300 rounded-md"
          maxLength="1"
          pattern="\d"
          inputMode="numeric"
          aria-label={`OTP digit ${index + 1}`}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)} // Handle backspace
          value={otps[index]}
        />
      ))}
     
    </div>
          <Link onClick={handleSubmit} className="bg-[#4486ff] text-white p-4 rounded-lg w-full text-center mt-5">
            Verify
          </Link>
        </form>
      </div>
      <p className="text-sm text-center font-400] absolute bottom-3 left-1/2 transform -translate-x-1/2 w-full">Did not recived code? <span className="text-[#37C2C1]"><Link to="/login">Resend.</Link></span></p>
    </div>
  )
}
