import React from 'react'
import {Link} from "react-router-dom"
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
const TermsAndCondition = () => {
  return (
    <>
       <div className='p-5'>
        <div className="text-3xl rounded-lg border h-8 w-8">
          <Link to='/about'> <MdOutlineKeyboardArrowLeft /></Link>
        </div>

        <div className="main head ">
      <div className=" my-10">
        <div className="title">
          <div className="head ">
            <h4 className="font-bold pb-3 uppercase">TERMS AND CONDITIONS</h4>
            <span className="text-sm">
              Welcome to <strong>Medixfy,</strong> a platform provided by{" "}
              <strong>Maxify Web Solutions Pvt. Ltd.</strong> By using our
              platform to book appointments with healthcare providers, you agree
              to the following terms and conditions:
            </span>
          </div>

          <div>
            <h3 className="font-bold py-2.5">1. Introduction</h3>
            <ul className="text-sm pl-10">
              <li className="list-disc">
                By using the Medixfy application, you agree to abide by these
                Terms and Conditions. Please read them carefully before
                proceeding.
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold py-2.5">2. Services Provided</h3>
            <ul className="text-sm pl-10">
              <li className="list-disc">
                Medixfy allows users to book appointments with registered
                doctors and healthcare providers. Maxify acts solely as an
                intermediary to facilitate these appointments.
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold py-2.5">3. Payment Terms</h3>
            <ul className="text-sm pl-10">
              <li className="list-disc">
                <strong>Total Fees : </strong>
                The total amount collected includes the doctor’s fee and a
                platform service charge.
              </li>

              <li className="list-disc pt-3">
                <strong>Doctor’s Fee : </strong> The doctor’s fee component is
                payable directly to the doctor within 24 hours after the
                appointment.
              </li>

              <li className="list-disc pt-3">
                <strong>Platform Charges : </strong> A service charge of ₹21
                (inclusive of GST) is retained by Medixfy’s the platform fee.
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold py-2.5">
              4. Cancellation and Refund Policy
            </h3>
            <ul className="text-sm pl-10">
              <li className="list-disc">
                <strong>Full Refund of Doctor's Fee : </strong>
                If an appointment is canceled by the user, the doctor’s fee will
                be fully refunded to the user.
              </li>

              <li className="list-disc pt-3">
                <strong>Non-Refundable Platform Charge : </strong> The platform
                service charge will not be refunded in the event of a
                cancellation.
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold py-2.5">5. TDS Policy</h3>
            <ul className="text-sm pl-10">
              <li className="list-disc">
                Medixfy does not deduct Tax Deducted at Source (TDS) from the
                doctor’s fee.
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold py-2.5">
              6. Account and Payment Information
            </h3>
            <ul className="text-sm pl-10">
              <li className="list-disc">
                By registering as a doctor, you agree to provide accurate bank
                details for prompt payments. Any payment delays due to incorrect
                information shall not be Medicare’s responsibility.
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold py-2.5">7. Privacy Policy</h3>
            <ul className="text-sm pl-10">
              <li className="list-disc">
                Medixfy values your privacy and shall handle all user data in
                accordance with our Privacy Policy. By using the application,
                you consent to our data collection and usage practices.
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold py-2.5">8. Modification of Terms</h3>
            <ul className="text-sm pl-10">
              <li className="list-disc">
                Medixfy reserves the right to modify or update these Terms and
                Conditions at any time. Updated terms will be effective upon
                posting within the application.
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold py-2.5">9. Governing Law</h3>
            <ul className="text-sm pl-10">
              <li className="list-disc pb-5">
                These Terms and Conditions shall be governed by the laws of
                [Applicable Jurisdiction]. By clicking "Accept," you agree to
                these Terms and Conditions.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
        </div>
    </>
  )
}

export default TermsAndCondition
