import React from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { Link } from "react-router-dom";

const PricingDetails = () => {
  return (
    <div className=" p-5 min-h-[100vh]">
      <div className="text-3xl rounded-lg border h-8 w-8">
        <Link to='/about'> <MdOutlineKeyboardArrowLeft /></Link>
      </div>
      <div className="mb-10  pt-5">
        <div className="main">
          <h2 className="text-xl font-bold pb-5">Pricing Details</h2>
        </div>
        <div className="pb-5 text-sm">
          <span>
            At Medixfy, we aim to offer transparent and fair pricing for our
            users. Below is a breakdown of the fees involved in booking a
            consultation:
          </span>
        </div>
        <div>
          <ol className="text-sm pb-5">
            <li className="font-bold pb-2">1. Total Consultation Fee</li>
            <span>
              The total amount paid for each consultation includes two
              components:
            </span>
            <ul className="text-sm pb-5 pl-10">
              <li className="list-disc py-2.5">
                <strong>Doctor's Fee : </strong>
                This is the amount charged by the doctor for the consultation.
              </li>
              <li className="list-disc py-2.5">
                <strong>Platform Service Charge : </strong>
                This is a nominal service fee retained by Medixfy for using the
                platform.
              </li>
            </ul>
            <li className="font-bold pb-2">2. Doctor’s Fee</li>
            <ul className="pl-10">
              <li className="list-disc py-2.5">
                The <strong>doctor’s fee</strong> is paid directly to the doctor
                within 24 hours after the consultation is completed.
              </li>
              <li className="list-disc py-2.5">A doctor's fee varies based on their experience and specialization, ranging from ₹100 to ₹2000</li>
            </ul>
            <li>
              <strong>3. Platform Charges</strong>
              <ul className="text-sm list-disc pl-10">
                <li className="py-2.5">
                  <strong>Platform Service Charge:</strong> A service charge of
                  ₹21 (inclusive of GST) is retained by Medixfy for providing
                  the platform service.
                </li>
              </ul>
            </li>
          </ol>
          <div>
            <h2 className="text-lg font-bold pb-5">
              Cancellation and Refund Policy
            </h2>
            <ul className="list-disc text-sm pl-10">
              <li className="py-2.5">
                <strong>Full Refund of Doctor’s Fee</strong>: If the user
                cancels the appointment before it takes place, the full doctor’s
                fee will be refunded to the user.
              </li>
              <li className="py-2.5">
                <strong>Non-Refundable Platform Charge</strong>: The{" "}
                <strong>platform service charge</strong>is non-refundable if the
                appointment is canceled, as it covers the operational costs of
                the platform.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingDetails;
