import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./spinner.css";
import { getUserDetails } from "../store/reducer/authReducer";

const Spinner = ({ path = "login" }) => {
  const [count, setCount] = useState(3);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch=useDispatch()
  const { userDetail } = useSelector((state) => state.auth);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevValue) => (prevValue > 0 ? prevValue - 1 : 0)); // Ensures the count doesn't go negative
    }, 1000);

    // Navigate when count reaches 0 and userDetail is absent
    if (!userDetail && count === 0) {
      navigate(`/${path}`, {
        state: location.pathname,
      });
    }

    // Clear interval when component unmounts
    return () => clearInterval(interval);
  }, [count, navigate, location, path, userDetail]);


//   const getData = useCallback(() => {
//     dispatch(getUserDetails());
//     }, [ dispatch]);

// useEffect(() => {
//     getData()
   
// }, [getData]);

  return (
    <>
      {
        <div className="my-loader">
          <svg
            className="spinner"
            width="65px"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="path"
              fill="none"
              strokeWidth={6}
              strokeLinecap="round"
              cx={33}
              cy={33}
              r={30}
            />
          </svg>
        </div>
      }
    </>
  );
};

export default Spinner;
