// propertySlice.js
import { api } from "../api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const getuserDoctor = createAsyncThunk(
  "auth/doctor-user",
  async ({name,city, page, limit}, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/get-user-doctors?city=${city}&name=${name}&page=${page}&limit=${limit}`);

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getDoctor = createAsyncThunk(
  "auth/doctor",
  async ({name, page, limit}, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/get-doctors?name=${name}&page=${page}&limit=${limit}`);

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchDoctor = createAsyncThunk(
  "auth/doctor-search",
  async (search, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(`/admin/search-doctor?search=${search}`);

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSingleDoctor = createAsyncThunk(
  "auth/get-single-doctor",
  async (slug, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/get-doctors/${slug}`);

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDoctorById = createAsyncThunk(
  "auth/get-doctor-by-id",
  async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/admin/get-doctor/${id}`);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteDoctorById = createAsyncThunk(
  "auth/delete-doctor-by-id",
  async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.delete(`/admin/delete-doctor/${id}`);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateDoctorById = createAsyncThunk(
  "auth/update-doctor-by-id",
  async ({ id, formData }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.put(`/admin/update-doctor/${id}`, formData);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateByDoctorMe = createAsyncThunk(
  "auth/update-by-doctor",
  async ({ id, formData }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.put(`/update-by-doctor/${id}`, formData);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDoctorStatus = createAsyncThunk(
  "auth/update-doctor-status",
  async ({ doctorId, status }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.put(`/admin/update-doctor-status`, {
        doctorId,
        status,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Admin Controller add doctor in admin
export const addDoctor = createAsyncThunk(
  "/admin/add-doctor",
  async (formData, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(`/admin/add-doctor`, formData, {
        withCredentials: true,
      });

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const loginDoctor = createAsyncThunk(
  '/admin/doctor-login',
  async (loginObj, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(`/admin/doctor-login`,  loginObj, {withCredentials:true});
      
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// city
export const getcityByname = createAsyncThunk(
  "auth/get-city-name",
  async (slug, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/admin/get-city-name?slug=${slug}`, {
        withCredentials: true,
      });

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllCity = createAsyncThunk(
  "auth/get-city",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/admin/get-city`, {
        withCredentials: true,
      });

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCityById = createAsyncThunk(
  "auth/get-city-byid",
  async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/admin/get-city/${id}`, {
        withCredentials: true,
      });

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateCity = createAsyncThunk(
  "auth/get-city-update",
  async ({ id, name }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.put(
        `/admin/update-city/${id}`,
        { name },
        { withCredentials: true }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteCity = createAsyncThunk(
  "auth/get-city-delete",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await api.delete(`/admin/delete-city/${id}`, {
        withCredentials: true,
      });
      return data; // Return data directly
    } catch (error) {
      // Ensure that error handling is robust
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" }
      );
    }
  }
);
export const activeDoctor = createAsyncThunk(
  "auth/active-doctor",
  async ({id ,doctorStatus}, { rejectWithValue }) => {
    try {
      const { data } = await api.put(`/active-doctor/${id}`,{doctorStatus}, {
        withCredentials: true,
      });
      return data; // Return data directly
    } catch (error) {
      // Ensure that error handling is robust
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" }
      );
    }
  }
);

export const addCity = createAsyncThunk(
  "doctor/addCity",
  async (name, { rejectWithValue, fulfillWithValue }) => {
    try {
      // Sending the formData to the API
      const { data } = await api.post(
        `/admin/add-city`,
        { name: name },
        { withCredentials: true }
      );
      return fulfillWithValue(data);
    } catch (error) {
      // Return the error message from the response
      return rejectWithValue(error.response.data);
    }
  }
);

export const doctorReducer = createSlice({
  name: "doctor",
  initialState: {
    loader: false,
    errorMessage: "",
    successMessage: "",
    doctorDetail: null, // add userDetail to initialState
  },
  reducers: {
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
    user_reset: (state) => {
      state.doctorInfo = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getuserDoctor.pending, (state) => {
        state.loader = true;
      })
      .addCase(getuserDoctor.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getuserDoctor.fulfilled, (state, { payload }) => {
        let userdoctorDetail = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.userdoctorDetail = userdoctorDetail;
      })
      .addCase(getDoctor.pending, (state) => {
        state.loader = true;
      })
      .addCase(getDoctor.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getDoctor.fulfilled, (state, { payload }) => {
        let doctorDetail = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.doctorDetail = doctorDetail;
      })
      .addCase(searchDoctor.pending, (state) => {
        state.loader = true;
      })
      .addCase(searchDoctor.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(searchDoctor.fulfilled, (state, { payload }) => {
        let doctorDetail = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.doctorDetail = doctorDetail;
      })
      .addCase(getSingleDoctor.pending, (state) => {
        state.loader = true;
      })
      .addCase(getSingleDoctor.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getSingleDoctor.fulfilled, (state, { payload }) => {
        let doctorInfo = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.doctorInfo = doctorInfo;
      })
      .addCase(addDoctor.pending, (state) => {
        state.loader = true;
      })
      .addCase(addDoctor.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(addDoctor.fulfilled, (state, { payload }) => {
        let admindoctorInfo = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.admindoctorInfo = admindoctorInfo;
      })
      //  doctor id
      .addCase(getDoctorById.pending, (state) => {
        state.loader = true;
      })
      .addCase(getDoctorById.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getDoctorById.fulfilled, (state, { payload }) => {
        let admindoctorId = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.admindoctorId = admindoctorId;
      })
      // delete doctor id
      .addCase(deleteDoctorById.pending, (state) => {
        state.loader = true;
      })
      .addCase(deleteDoctorById.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(deleteDoctorById.fulfilled, (state, { payload }) => {
        let deletedoctorId = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.deletedoctorId = deletedoctorId;
      })
      // update doctor id
      .addCase(updateDoctorById.pending, (state) => {
        state.loader = true;
      })
      .addCase(updateDoctorById.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(updateDoctorById.fulfilled, (state, { payload }) => {
        var doctorDetails = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.doctorDetails = doctorDetails;
      })
      // update doctor id
      .addCase(updateByDoctorMe.pending, (state) => {
        state.loader = true;
      })
      .addCase(updateByDoctorMe.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(updateByDoctorMe.fulfilled, (state, { payload }) => {
        var updateByDoctorMeDetails = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.updateByDoctorMeDetails = updateByDoctorMeDetails;
      })
      // update doctor status
      .addCase(updateDoctorStatus.pending, (state) => {
        state.loader = true;
      })
      .addCase(updateDoctorStatus.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(updateDoctorStatus.fulfilled, (state, { payload }) => {
        let doctorDetail = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.doctorDetail = doctorDetail;
      })
      // city
      .addCase(getAllCity.pending, (state) => {
        state.loader = true;
      })
      .addCase(getAllCity.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getAllCity.fulfilled, (state, { payload }) => {
        let cityData = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.cityData = cityData;
      })

      .addCase(getcityByname.pending, (state) => {
        state.loader = true;
      })
      .addCase(getcityByname.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getcityByname.fulfilled, (state, { payload }) => {
        state.payload = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
      })

      // delete city
      .addCase(deleteCity.pending, (state) => {
        state.loader = true;
      })
      .addCase(deleteCity.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(deleteCity.fulfilled, (state, { payload }) => {
        let deletecitybyid = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.deletecitybyid = deletecitybyid;
      })
      //
      .addCase(updateCity.pending, (state) => {
        state.loader = true;
      })
      .addCase(updateCity.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(updateCity.fulfilled, (state, { payload }) => {
        let cityData = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.cityData = cityData;
      })
      //
      .addCase(addCity.pending, (state) => {
        state.loader = true;
      })
      .addCase(addCity.rejected, (state, { payload }) => {
        console.log("Login rejected payload:", payload); // Log payload
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(addCity.fulfilled, (state, { payload }) => {
         let citydata=payload.data;
         state.successMessage = payload.message;
         state.loader = false;
         state.citydata=citydata;
      })

      .addCase(activeDoctor.pending, (state) => {
        state.loader = true;
      })
      .addCase(activeDoctor.rejected, (state, { payload }) => {
        console.log("Login rejected payload:", payload); // Log payload
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(activeDoctor.fulfilled, (state, { payload }) => {
         let activeDoctordata=payload.data;
         state.successMessage = payload.message;
         state.loader = false;
         state.activeDoctordata=activeDoctordata;
      })
      .addCase(loginDoctor.pending, (state) => {
        state.loader = true;
    })
    .addCase(loginDoctor.rejected, (state, { payload }) => {
        console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || 'An error occurred';
        state.loader = false;
    })
    .addCase(loginDoctor.fulfilled, (state, { payload }) => {
        let logindoctordetail = payload.data;
        
        // Update the state
        state.successMessage = payload.message;
        state.loader = false;
        state.logindoctordetail = logindoctordetail;
        state.isLoading = false;
        state.isError = false;
        state.isAuth = true;
        state.token = payload.token;
        state.user = payload.user;
        
        // Store token, user details, and auth status in localStorage
        localStorage.setItem("token", payload.token); // assuming payload contains token
        // localStorage.setItem("user", JSON.stringify(payload.user)); 
        localStorage.setItem("auth", true);
    });
    
    

  }
});

export const { messageClear, doctor_reset } = doctorReducer.actions;
export default doctorReducer.reducer;
