import React, { useState } from "react";

import contact from "../assets/contact.jpeg";
import { FaEnvelope, FaLocationDot, FaHouse } from "react-icons/fa6";
import { MdCall, MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { contactform } from "../store/reducer/authReducer";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch=useDispatch()
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
   };
   const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address:"",
    message:""
})

const inputHandle = (e) => {
  setState({
      ...state,      
      [e.target.name]: e.target.value,
  });
};
   
const handleSubmit=async()=>{
dispatch(contactform(state)).then((res)=>{
  if(res.payload.success){
    toast.success(res.payload.message)
    setState({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      message: ""
    });
  }else{
    toast.error(res.payload.message)
  }
})
}

  return (
    <div className=" p-5 h-min-full">
      <div className="">
      <div className="text-3xl rounded-lg border h-8 w-8">
          <Link to='/about'> <MdOutlineKeyboardArrowLeft /></Link>
        </div>
        <div className="mt-3  mb-3">
          <h2 className="text-3xl font-bold font-sans text-black">
            Contact Us
          </h2>
        </div>
        <p className="pt-3  text-black mb-2">
          Connect with us to experience seamless communication. we value open
          dialogue and are eager to engage with you. whether you have questions,
          ideas, or feedback, we are here to listen and respond.
        </p>
        <div className="mt-10">
          <img src={contact} alt="Contact Us" className="px-3" />
        </div>
        <div className="info grid grid-cols-2 grid-rows-2 gap-4 justify-items-center items-center text-center mt-8 mx-4">
          <div>
            <FaEnvelope className="mx-auto mb-2 text-2xl" />
            <span className="block font-bold">Email</span>
            <span className="text-black text-sm">academy@thecodemax.com</span>
          </div>
          <div>
            <FaLocationDot className="mx-auto mb-2 text-2xl" />
            <span className="block font-bold">Our Address</span>
            <span className="text-black text-sm">
              D34, read fm road, D Block, Sector 2, Noida, Uttar Pradesh 201301
            </span>
          </div>
          <div>
            <MdCall className="mx-auto mb-2 text-2xl" />
            <span className="block font-bold">Get In Touch</span>
            <span className="text-black">+91 9060636145</span>
          </div>
          <div>
            <FaHouse className="mx-auto mb-2 text-2xl" />
            <span className="block font-bold">Office Hours</span>
            <span className="text-black">09:00 AM - 6:00 PM </span>
          </div>
        </div>
        <div className="form">



          <div className="grid grid-cols-12 gap-3">
         
              <input
                type="text"
                placeholder="First Name"
                className="border border-gray-300 rounded   col-span-6 p-2 focus:outline-none"

                name="firstName"
                onChange={inputHandle}
                value={state.firstName}

              />
        
         
              <input
                type="text"
                placeholder="Last Name"
                className="border border-gray-300 rounded   col-span-6 p-2 focus:outline-none"
                name="lastName"
                onChange={inputHandle}
                value={state.lastName}
              />
       
         
              <input
                type="email"
                placeholder="Email Address"
                className="border border-gray-300 rounded   col-span-6 p-2 focus:outline-none"
                name="email"
                onChange={inputHandle}
                value={state.email}
              />
     
              <input
                type="phone"
                placeholder="Phone"
                className="border border-gray-300 rounded   col-span-6 p-2 focus:outline-none"
                name="phone"
                onChange={inputHandle}
                value={state.phone}
              />
       
         
              <input
                type="address"
                placeholder="Address"
                className="border border-gray-300 rounded   col-span-12 p-2 focus:outline-none"
                name="address"
                onChange={inputHandle}
                value={state.address}
              />
          
              <textarea
                type="message"
                placeholder="Write Your Message"
                className="border border-gray-300 rounded col-span-12  p-2 focus:outline-none h-32" 
                name="message"
                onChange={inputHandle}
                value={state.message}
              />
              
        
          </div>
        </div>
        <div className="tc ">
        <label className="flex items-center mt-2">
                                    <input
                                        type="checkbox"
                                        className="hidden peer"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    <div className="w-6 h-6 rounded-full border-2 border-gray-300 flex items-center justify-center peer-checked:border-blue-500 peer-checked:bg-blue-500 ">
                                        <svg
                                            className={`w-4 h-4 text-white ${isChecked ? 'block' : 'hidden'}`}
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M16.707 5.293a1 1 0 010 1.414l-7.5 7.5a1 1 0 01-1.414 0l-3.5-3.5a1 1 0 111.414-1.414L8 11.586l6.793-6.793a1 1 0 011.414 0z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                    <span className="gray-100 ms-2 mr-2 fs-sm cursor-pointer text-[12px]">  I agree that my submitted data is being collected and stored.</span>
                                </label>
                 </div>
        <div className="mt-5 flex ">
          <button onClick={handleSubmit} className="border rounded-md border-blue-500 px-3 py-1 text-xl text-white bg-blue-500">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
