// propertySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import Cookies from 'js-cookie';
import { api } from "../api";

export const register = createAsyncThunk(
  'auth/register',
  async (formData, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post('/register', formData);

      Cookies.set('token', data?.user?.token, { secure: true, sameSite: 'None' });
      
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const login = createAsyncThunk(
  'auth/login',
  async (formData, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post('/login', formData);

      // Cookies.set('token', data?.data?.token, { secure: true, sameSite: 'None' });
      

      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 5);
      
      // Set the cookie with the specified expiration date
      Cookies.set("token", data?.data?.token, {
        secure: true,
        sameSite: "None",
        expires: expirationDate
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const optSend = createAsyncThunk(
  'auth/optSend',
  async (phone, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post('/opt-send', {phone:phone});

      Cookies.set('token', data?.data?.token, { secure: true, sameSite: 'None' });
      
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const optverify = createAsyncThunk(
  'auth/optverify',
  async (otp, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post('/opt-verify', {otp:otp},{withCredentials:true});

      
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetePassword = createAsyncThunk(
  'auth/reset-password',
  async ({password,cpassword}, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post('/reset-password', {password:password,cpassword:cpassword},{withCredentials:true});
      
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  'auth/me',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get('/me',{withCredentials:true});
   
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "auth/get-all-users",
  async ({userId, page, limit}, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/get-all-users?userId=${userId}&page=${page}&limit=${limit}`, {
        withCredentials: true,
      });

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserbyId = createAsyncThunk(
  "auth/get-users-by-id",
  async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/get-users-by-id/${id}`, {
        withCredentials: true,
      });

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserbyId = createAsyncThunk(
  "auth/delete-users-by-id",
  async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.delete(`/delete-users-by-id/${id}`);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const contactform = createAsyncThunk(
  "auth/contactform",
  async (formData, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(`/contact`,formData);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);







export const authReducer = createSlice({
  name: 'auth',
  initialState: {
    loader: false,
    errorMessage: '',
    successMessage: '',
    userDetail: null, // add userDetail to initialState
    userInfo: null,
  },
  reducers: {
    messageClear: (state) => {
      state.errorMessage = '';
      state.successMessage = '';
    },
    user_reset: (state) => {
      state.userInfo = null;   // Use semicolon instead of comma
      state.userDetail = null; // Use semicolon instead of comma
    }
  }
  ,
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.loader = true;
      })
      .addCase(register.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || 'An error occurred';
        state.loader = false;
      })
      .addCase(register.fulfilled, (state, { payload }) => {
         state.payload=payload;
        state.successMessage = payload.message;
        state.loader = false;
      })
      .addCase(login.pending, (state) => {
        state.loader = true;
      })
      .addCase(login.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || 'An error occurred';
        state.loader = false;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
         state.payload=payload;
        state.successMessage = payload.message;
        state.loader = false;
      })
      .addCase(optSend.pending, (state) => {
        state.loader = true;
      })
      .addCase(optSend.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || 'An error occurred';
        state.loader = false;
      })
      .addCase(optSend.fulfilled, (state, { payload }) => {
         state.payload=payload;
        state.successMessage = payload.message;
        state.loader = false;
      })
      .addCase(optverify.pending, (state) => {
        state.loader = true;
      })
      .addCase(optverify.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || 'An error occurred';
        state.loader = false;
      })
      .addCase(optverify.fulfilled, (state, { payload }) => {
         state.payload=payload;
        state.successMessage = payload.message;
        state.loader = false;
      })
      

      .addCase(resetePassword.pending, (state) => {
        state.loader = true;
      })
      .addCase(resetePassword.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || 'An error occurred';
        state.loader = false;
      })
      .addCase(resetePassword.fulfilled, (state, { payload }) => {
         state.payload=payload;
        state.successMessage = payload.message;
        state.loader = false;
      })
      .addCase(getUserDetails.pending, (state) => {
        state.loader = true;
      })
      .addCase(getUserDetails.rejected, (state, { payload }) => {
        // console.log('Login rejected payload:', payload); // Log payload
        state.errorMessage = payload?.error || 'An error occurred';
        state.loader = false;
      })
      .addCase(getUserDetails.fulfilled, (state, { payload }) => {     
         let userDetail=payload.data;
         state.successMessage = payload.message;
         state.loader = false;
         state.userDetail=userDetail;
      })
      // all users data 
      .addCase(getAllUsers.pending, (state) => {
        state.loader = true;
      })
      .addCase(getAllUsers.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        let usersData = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.usersData = usersData;
      })
      // all users data by id
      .addCase(getUserbyId.pending, (state) => {
        state.loader = true;
      })
      .addCase(getUserbyId.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(getUserbyId.fulfilled, (state, { payload }) => {
        let userbyid = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.userbyid = userbyid;
      })
      // Delete users data by id
      .addCase(deleteUserbyId.pending, (state) => {
        state.loader = true;
      })
      .addCase(deleteUserbyId.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(deleteUserbyId.fulfilled, (state, { payload }) => {
        let userbyid = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
        state.userbyid = userbyid;
      })
      .addCase(contactform.pending, (state) => {
        state.loader = true;
      })
      .addCase(contactform.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error || "An error occurred";
        state.loader = false;
      })
      .addCase(contactform.fulfilled, (state, { payload }) => {
        state.contactformdata = payload.data;
        state.successMessage = payload.message;
        state.loader = false;
      
      })
     
  }
});

export const { messageClear, user_reset } = authReducer.actions;
export default authReducer.reducer;
